.footer {
    padding: 70rem 0 50rem;

    &__inner {
        max-width: 910rem;

        @include mob {
            width: 100%;
        }
    }

    &__nav {
        align-items: center;
        justify-content: center;
        margin-bottom: 56rem;

        @include mob {
            flex-direction: column;
            align-items: center;
            margin-bottom: 70rem;
        }

        &Item {
            color: #fff;
            font-weight: 600;
            font-size: 14rem;
            text-transform: uppercase;

            @include mob {
                font-size: 16rem;
            }

            & + & {
                margin-left: 74rem;

                @include mob {
                    margin-top: 52rem;
                    margin-left: 0;
                }
            }
        }
    }

    &__content {
        color: #fff;
        font-size: 12rem;
        line-height: 1.7;
        text-align: center;

        @include mob {
            max-width: 90%;
            margin: 0 auto;
            font-size: 16rem;
            line-height: 1.63;
            text-align: center;
        }
    }
}
