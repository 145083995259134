.index {
    background: #e11f26;

    &._5ka {
        position: relative;
        z-index: 1;

        // &::after {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     z-index: 3;
        //     width: 100%;
        //     height: 100%;
        //     content: "";
        //     pointer-events: none;

        //     @include bg("/media/5ka/back-5ka.svg", 100% auto, center 688rem);

        //     @include mob {
        //         @include bg("/media/5ka/back-5ka-mob.svg", 100% auto, center 480rem);
        //     }
        // }
    }

    &__section {
        &._about {
            position: relative;
            z-index: 2;
        }
    }

    &._5ka &__section {
        position: relative;

        &._header {
            z-index: 2;
        }

        z-index: 4;
    }
}
