@font-face {
    font-weight: 300;
    font-family: Onest;
    font-style: normal;
    src: url("../fonts/Onest/Onest-Light.ttf");
}

@font-face {
    font-weight: 400;
    font-family: Onest;
    font-style: normal;
    src: url("../fonts/Onest/Onest-Regular.ttf");
}

@font-face {
    font-weight: 500;
    font-family: Onest;
    font-style: normal;
    src: url("../fonts/Onest/Onest-Medium.ttf");
}

@font-face {
    font-weight: 600;
    font-family: Onest;
    font-style: normal;
    src: url("../fonts/Onest/Onest-Bold.ttf");
}

@font-face {
    font-weight: 700;
    font-family: Onest;
    font-style: normal;
    src: url("../fonts/Onest/Onest-Black.ttf");
}
