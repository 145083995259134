.upload {
    position: relative;
    width: 100%;
    height: 100%;
    background: #f3f3f3;
    border: 3rem dashed #10cfc9;
    border-radius: 14rem;

    @include button;

    &._upload {
        border: 3rem solid #10cfc9;
    }

    &._upload &__main {
        opacity: 0;
    }

    &._over {
        border-color: #ffd100;
    }

    & input {
        display: none;
    }

    &__main {
        padding: 40rem 20rem;
        transition: $trans;

        &Icon {
            width: 22rem;
            height: 22rem;
            margin-bottom: 18rem;
        }

        &Description {
            color: $colorDark;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.4;
            text-align: center;
        }
    }

    &__result {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        padding: 20rem;

        &Name {
            max-width: 100%;
            margin-bottom: 16rem;
            padding: 4rem 10rem;
            overflow: hidden;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.4;
            white-space: nowrap;
            text-align: center;
            text-overflow: ellipsis;
            background: #ffd100;
            border-radius: 3rem;
        }

        &Button {
            display: flex;
            align-items: center;
            color: $colorDark;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.4;

            &Icon {
                width: 24rem;
                height: 24rem;
                margin-right: 8rem;
            }
        }
    }

    &__over {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        padding: 20rem;
        background: #ffd100;
        border-radius: inherit;

        &Text {
            color: $colorDark;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.4;
        }
    }
}
