.popup {
    position: relative;
    z-index: 1;
    padding: 100rem 0 60rem;
    overflow: hidden;
    overflow-y: auto;

    @include mob {
        padding: 80rem 0 32rem;
    }

    ._chatbot & {
        padding: 60rem 0;

        @include mob {
            padding: 32rem 0;
        }
    }

    &__inner {
        position: relative;
        z-index: 2;
        width: 600rem;
        margin: auto;

        @include mob {
            width: calc(100% - 40rem);
            margin: 0 auto;
        }
    }

    &:not(._withBlocks) &__inner {
        // overflow: hidden;
        background: #fff;
        border-radius: 32rem;

        @include mob {
            border-radius: 24rem;
        }
    }

    &__close {
        position: absolute;
        top: 18rem;
        right: 18rem;
        z-index: 2;
    }

    &__head {
        justify-content: center;

        // margin-bottom: -12rem;
        padding: 24rem 20rem;
        border-radius: 32rem;

        @include bg("/media/popup-head-back.svg", cover, center);

        @include mob {
            min-height: 70rem;
            padding: 16rem;
            border-radius: 24rem;
        }
    }

    &__head {
        background-color: #fcdbc6;
    }

    &__title {
        color: #5d279e;
        font-weight: 700;
        font-size: 24rem;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;

        @include mob {
            font-size: 21rem;
        }
    }

    &__description {
        max-width: 80%;
        margin-top: 4rem;
        color: $colorDark;
        font-size: 16rem;
        line-height: 1.3;
        text-align: center;

        @include mob {
            max-width: none;
            font-weight: 500;
            font-size: 17rem;
        }
    }

    &._merch &__description {
        white-space: nowrap;
    }

    &__height {
        min-height: 200rem;

        @include mob {
            min-height: auto;
        }
    }

    &__content {
        width: 100%;
        padding: 28rem 40rem;

        @include mob {
            padding: 22rem 12rem;
        }
    }

    &._merch &__content {
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    &__blocks {
        width: 100%;

        &Item {
            top: 50%;
            width: 100%;
            transform: translate(0, -50%);

            &:not([data-show])._prev {
                transform: translate(-32rem, -50%);
            }

            &:not([data-show])._next {
                transform: translate(32rem, -50%);
            }
        }
    }

    &__block {
        width: 100%;
        padding: 28rem 40rem;

        @include mob {
            padding: 22rem 16rem;
        }
    }

    &__cheque {
        margin-bottom: 28rem;

        &Description {
            max-width: 80%;
            margin-bottom: 32rem;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;

            @include mob {
                max-width: 100%;
            }
        }

        &Area {
            padding: 30rem;
            background: #f3f3f3;
            border: 2rem dashed #10cfc9;
            border-radius: 12rem;

            &Icon {
                width: 36rem;
                height: 36rem;
                margin-bottom: 12rem;
            }

            &Description {
                font-weight: 500;
                font-size: 16rem;
                line-height: 1.3;
                text-align: center;

                @include mob {
                    font-size: 18rem;
                }
            }
        }
    }

    &__scan {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 340rem;
        overflow: hidden;
        border-radius: 20rem;

        & canvas {
            position: relative;
            z-index: 100;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 28rem;

        &Field {
            width: 100%;
            margin-bottom: 8rem;

            &._short {
                width: calc(50% - 8rem);
                margin-bottom: 8rem;
            }

            &._center .field__support,
            &._center .input__support,
            &._center .input__field {
                text-align: center;
            }

            & .input__support {
                color: rgba($colorDark, 0.32);
            }

            &Link {
                margin: 5rem 0 0 auto;
                color: #ff49b8;
                font-weight: 500;
                font-size: 16rem;
                line-height: 1.2;

                @include button;

                @include mob {
                    font-size: 18rem;
                }
            }
        }

        &Upload {
            min-height: 100rem;
            margin: 8rem 0 0;
            padding: 20rem;
            background: #f3f3f3;
            border: 2rem dashed #10cfc9;
            border-radius: 12rem;

            & input {
                display: none;
            }

            &Text {
                max-width: 80%;
                font-weight: 500;
                font-size: 16rem;
                line-height: 1.3;
                text-align: center;

                @include mob {
                    max-width: 90%;
                    font-size: 18rem;
                }
            }

            &File {
                position: relative;
                z-index: 1;
                width: 48rem;
                height: 48rem;
                margin-bottom: 8rem;
                overflow: hidden;
                border-radius: 10rem;

                & + & {
                    margin-left: 10rem;
                }

                &Preview {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &Delete {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 3;
                    width: 20rem;
                    height: 20rem;
                    background: #5d279e;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);

                    &Icon {
                        width: 8rem;
                        height: 8rem;

                        @include icon(#fff);
                    }
                }
            }
        }
    }

    &__socials {
        margin-top: 14rem;

        @include mob {
            margin-top: 18rem;
        }

        &Title {
            margin-bottom: 8rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;
        }

        &Items {
            justify-content: center;
        }

        &Item {
            width: 56rem;
            height: 56rem;
            background: #f3f3f3;
            border-radius: 12rem;

            @include button;

            @include mob {
                width: 64rem;
                height: 64rem;
                border-radius: 16rem;
            }

            & + & {
                margin-left: 12rem;

                @include mob {
                    margin-left: 16rem;
                }
            }

            &Icon {
                width: 24rem;
                height: 24rem;
                object-fit: contain;

                @include mob {
                    width: 30rem;
                    height: 30rem;
                }
            }
        }
    }

    &__foot {
        margin-top: 22rem;
    }

    &__error {
        width: 100%;
        transition: $trans;

        &._info {
            margin-top: 20rem;
        }

        &:not(._empty) {
            margin-bottom: 20rem;
        }

        &._info .error__itemInner {
            background: #10cfc9;
        }
    }

    &__dynamicButtons {
        display: flex;
        justify-content: center;
        width: 100%;

        & .list__item {
            width: 100%;

            &Box {
                display: flex;
                justify-content: center;
            }
        }
    }

    &__buttons {
        align-items: center;
        justify-content: center;
        width: 100%;

        @include mob {
            flex-direction: column-reverse;
        }
    }

    &__button {
        height: 68rem;

        &._big {
            height: 88rem;

            @include mob {
                height: 68rem;
            }
        }

        @include mob {
            width: 100%;
        }

        &._fix {
            width: 220rem;

            @include mob {
                width: 100%;
            }
        }

        &._auto {
            @include mob {
                width: auto;
            }
        }

        & + & {
            margin-left: 13rem;

            @include mob {
                margin-bottom: 12rem;
                margin-left: 0;
            }
        }
    }

    &__code {
        &Text {
            margin-bottom: 14rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.4;
            text-align: center;

            & a {
                color: #ff49b8;
                font-weight: 500;
                border-bottom: 1px solid #ff49b8;
            }
        }

        &Email {
            margin: 12rem 0;
            padding: 6rem 20rem;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;
            background: #ffd100;
            border-radius: 12rem;

            @include mob {
                padding: 9rem 20rem;
            }
        }

        &Inputs {
            width: 100%;
        }
    }

    &__table {
        margin-bottom: 28rem;

        @include mob {
            width: calc(100% + 12rem);
        }
    }

    &__invite {
        &Text {
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;

            @include mob {
                font-size: 18rem;
            }

            &._code {
                padding-top: 8rem;
            }

            & + & {
                margin-top: 8rem;
            }

            &._main {
                color: #ff49b8;
                font-weight: 600;

                @include mob {
                    font-size: 20rem;
                }
            }

            & a {
                display: inline;
                color: #ff49b8;
                font-weight: 500;
                border-bottom: 1px solid #ff49b8;
            }

            & b {
                color: #5d279e;
                font-weight: 700;
                line-height: 1.3;
                text-align: center;

                @include mob {
                    display: block;
                }
            }
        }

        &Info {
            margin-top: 24rem;
            padding: 24rem 32rem;
            background: #ffd100;
            border-radius: 32rem;

            @include mob {
                padding-top: 20rem;
            }
        }
    }

    &__success {
        padding: 32rem;
        background: #ffd100;
        border-radius: 32rem;

        &._bottom {
            margin-bottom: 48rem;
        }

        @include mob {
            padding: 20rem;
        }

        &Title {
            margin-bottom: 8rem;
            color: #ff49b8;
            font-weight: 600;
            font-size: 24rem;
            line-height: 1.3;
            text-align: center;
        }

        &Text {
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;
        }
    }

    &__scanError {
        margin-bottom: 40rem;

        &Text {
            margin-bottom: 16rem;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;
        }

        &Value {
            margin-bottom: 40rem;
        }

        &Button {
            @include mob {
                height: 66rem;
            }
        }
    }

    &__merchInfo {
        position: absolute;
        top: 42rem;
        left: 50%;
        z-index: 100;
        width: 400rem;
        padding: 12rem 16rem;
        color: #5d279e;
        font-weight: 500;
        font-size: 14rem;
        line-height: 1.3;
        text-align: center;
        background: #fff;
        border-radius: 16rem;
        box-shadow: 0 12rem 12rem 0 rgba(#620000, 0.1);
        transform: translate(-50%, 0);
        transition: $trans;

        @include mob {
            top: 8rem;
            width: 330rem;
            padding: 10rem 8rem;
            font-size: 15rem;
            border-radius: 14rem;
        }

        &:not(._active) {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__merch {
        position: relative;
        margin-bottom: 24rem;

        @include mob {
            padding: 0 10rem;
        }

        &Button {
            position: absolute;
            top: 50%;
            z-index: 3;
            width: 48rem;
            height: 48rem;
            background: #ff49b8;
            border-radius: 50%;
            transform: translate(0, -50%);

            &._prev {
                left: 0;
            }

            &._next {
                right: 0;
            }

            &Icon {
                width: 20rem;
                height: 20rem;

                @include icon(#fff);
            }
        }

        &Items {
            display: inline-flex;
        }

        &Item {
            width: 520rem;

            @include mob {
                width: 330rem;
                margin-right: 10rem;
            }

            &Inner {
                width: 392rem;
                height: 364rem;

                @include mob {
                    width: 100%;
                }
            }
        }

        &Checkbox {
            margin-top: 32rem;
        }
    }

    &__subTitle {
        margin-bottom: 24rem;
        padding: 4rem 8rem;
        font-weight: 500;
        font-size: 16rem;
        line-height: 1.3;
        text-align: center;
        background: #ffd100;
        border-radius: 6rem;

        & span {
            position: relative;
            z-index: 1;
            display: inline-block;
            padding: 0 10rem;
            color: #fff;
            font-weight: 600;

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 22rem;
                height: 22rem;
                background: #5d279e;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                content: "";
            }
        }
    }

    &__raffle {
        position: relative;
        z-index: 1;
        height: 284rem;
        margin-bottom: 24rem;
        overflow: hidden;
        background: #ffd100;
        border-radius: 20rem;

        @include mob {
            width: calc(100% + 24rem);
        }

        &Decor {
            position: absolute;
            z-index: 2;
            transition: $trans;
            will-change: transform;
        }

        &Decor._1 {
            top: 60rem;
            left: -10rem;
            width: 66rem;
            height: 66rem;

            @include mob {
                top: 78rem;
                left: -6rem;
                width: 44rem;
                height: 44rem;
            }
        }

        &Decor._1 &DecorInner {
            @include bg("/media/raffle/decor-1.svg", contain, center);
        }

        &._process:not(._complete) &Decor._1 &DecorInner {
            animation: raffleDecor1 1s infinite linear;

            @keyframes raffleDecor1 {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

        &Decor._2 {
            top: 34rem;
            left: 105rem;
            width: 30rem;
            height: 39rem;

            @include mob {
                top: 50rem;
                left: 70rem;
                width: 28rem;
                height: 28rem;
            }
        }

        &Decor._2 &DecorInner {
            @include bg("/media/raffle/decor-2.svg", contain, center);
        }

        &._process &Decor._2 {
            transform: translate(-32rem, 67rem);
        }

        &._process:not(._complete) &Decor._2 &DecorInner {
            animation: raffleDecor2 1s infinite ease-in-out;

            @keyframes raffleDecor2 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(0, -10rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &Decor._3 {
            top: 1rem;
            left: 125rem;
            width: 18rem;
            height: 23rem;

            @include mob {
                top: 20rem;
                left: 90rem;
                width: 14rem;
                height: 16rem;
            }
        }

        &Decor._3 &DecorInner {
            @include bg("/media/raffle/decor-3.svg", contain, center);
        }

        &._process:not(._complete) &Decor._3 &DecorInner {
            animation: raffleDecor3 1s infinite ease-in-out;

            // animation-delay: 0.2s;

            @keyframes raffleDecor3 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(0, 10rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &Decor._4 {
            top: -39rem;
            left: 224rem;
            width: 54rem;
            height: 122rem;

            @include mob {
                top: 5rem;
                left: 160rem;
                width: 34rem;
                height: 90rem;
            }
        }

        &Decor._4 &DecorInner {
            @include bg("/media/raffle/decor-4.svg", contain, center);
        }

        &._process &Decor._4 {
            transform: translate(-26rem, -32rem) scale(0.8);
        }

        &._process:not(._complete) &Decor._4 &DecorInner {
            animation: raffleDecor4 1s infinite ease-in-out;

            // animation-delay: 0.3s;

            @keyframes raffleDecor4 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(0, 10rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &Decor._5 {
            top: 39rem;
            right: 130rem;
            width: 22rem;
            height: 17rem;

            @include mob {
                top: 60rem;
                right: 100rem;
            }
        }

        &Decor._5 &DecorInner {
            @include bg("/media/raffle/decor-5.svg", contain, center);
        }

        &._process &Decor._5 {
            transform: translate(16rem, -16rem);
        }

        &._process:not(._complete) &Decor._5 &DecorInner {
            animation: raffleDecor5 1s infinite ease-in-out;

            @keyframes raffleDecor5 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(-30deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &Decor._6 {
            top: 22rem;
            right: 31rem;
            width: 27rem;
            height: 27rem;

            @include mob {
                top: 40rem;
            }
        }

        &Decor._6 &DecorInner {
            @include bg("/media/raffle/decor-6.svg", contain, center);
        }

        &._process:not(._complete) &Decor._6 &DecorInner {
            animation: raffleDecor6 1s infinite linear;

            // animation-delay: 0.35s;

            @keyframes raffleDecor6 {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(-360deg);
                }
            }
        }

        &Decor._7 {
            top: 90rem;
            right: 17rem;
            width: 53rem;
            height: 31rem;

            @include mob {
                top: 100rem;
                width: 43rem;
                height: 25rem;
            }
        }

        &Decor._7 &DecorInner {
            @include bg("/media/raffle/decor-7.svg", contain, center);
        }

        &._process:not(._complete) &Decor._7 &DecorInner {
            animation: raffleDecor7 1s infinite ease-in-out;

            @keyframes raffleDecor7 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(-10rem, 0);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &Decor._8 {
            right: -17rem;
            bottom: 13rem;
            width: 62rem;
            height: 87rem;

            @include mob {
                bottom: 23rem;
                width: 55rem;
                height: 80rem;
            }
        }

        &Decor._8 &DecorInner {
            @include bg("/media/raffle/decor-8.svg", contain, center);
        }

        &._process:not(._complete) &Decor._8 &DecorInner {
            animation: raffleDecor8 1s infinite ease-in-out;

            // animation-delay: 0.2s;

            @keyframes raffleDecor8 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(-10deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &Decor._9 {
            right: 158rem;
            bottom: 35rem;
            width: 31rem;
            height: 41rem;

            @include mob {
                right: 90rem;
                bottom: 60rem;
            }
        }

        &Decor._9 &DecorInner {
            @include bg("/media/raffle/decor-9.svg", contain, center);
        }

        &._process &Decor._9 {
            transform: translate(20rem, 20rem);
        }

        &._process:not(._complete) &Decor._9 &DecorInner {
            animation: raffleDecor9 1s infinite ease-in-out;

            // animation-delay: 0.13s;

            @keyframes raffleDecor9 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(10rem, 10rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &Decor._10 {
            right: 234rem;
            bottom: 26rem;
            width: 25rem;
            height: 21rem;

            @include mob {
                right: 160rem;
                bottom: 48rem;
                width: 20rem;
            }
        }

        &Decor._10 &DecorInner {
            @include bg("/media/raffle/decor-10.svg", contain, center);
        }

        &._process &Decor._10 {
            transform: translate(-28rem, 28rem);
        }

        &._process:not(._complete) &Decor._10 &DecorInner {
            animation: raffleDecor10 1s infinite ease-in-out;

            // animation-delay: 0.31s;

            @keyframes raffleDecor10 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(-15deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &Decor._11 {
            bottom: 48rem;
            left: 54rem;
            width: 22rem;
            height: 24rem;

            @include mob {
                bottom: 80rem;
                left: 34rem;
                width: 20rem;
                height: 20rem;
            }
        }

        &Decor._11 &DecorInner {
            @include bg("/media/raffle/decor-11.svg", contain, center);
        }

        &._process:not(._complete) &Decor._11 &DecorInner {
            animation: raffleDecor11 1s infinite ease-in-out;

            // animation-delay: 0.1s;

            @keyframes raffleDecor11 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(-9rem, 9rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &Decor._12 {
            bottom: 17rem;
            left: 57rem;
            width: 20rem;
            height: 20rem;

            @include mob {
                bottom: 50rem;
                left: 34rem;
                width: 16rem;
                height: 16rem;
            }
        }

        &Decor._12 &DecorInner {
            border: 3rem solid #fff;
            border-radius: 50%;

            @include mob {
                border-width: 2rem;
            }
        }

        &._process:not(._complete) &Decor._12 &DecorInner {
            animation: raffleDecor12 1s infinite ease-in-out;

            // animation-delay: 0.1s;

            @keyframes raffleDecor12 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(0.8);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &Decor._13 {
            bottom: -9rem;
            left: 23rem;
            width: 30rem;
            height: 30rem;

            @include mob {
                bottom: 20rem;
                left: 13rem;
                width: 24rem;
                height: 24rem;
            }
        }

        &Decor._13 &DecorInner {
            border: 5rem solid #fff;
            border-radius: 50%;

            @include mob {
                border-width: 4rem;
            }
        }

        &._process:not(._complete) &Decor._13 &DecorInner {
            animation: raffleDecor13 1s infinite ease-in-out;

            // animation-delay: 0.21s;

            @keyframes raffleDecor13 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &Back {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            width: 646rem;
            height: 542rem;
            transform: translate(-50%, -50%);
            opacity: 0.1;
            transition: $trans;

            @include mob {
                width: 500rem;
                height: 500rem;
            }

            @include icon(#e11f26);
        }

        &._process &Back {
            @include icon(#fff);

            transform: translate(-55%, -47%) scale(1.2);
            opacity: 0.3;
        }

        &._process:not(._complete) &BackInner {
            animation: raffleBackDecor 1s infinite ease-in-out;

            @keyframes raffleBackDecor {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(-10deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &Button {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, -50%);
            transition: $trans;

            @include desk {
                white-space: nowrap;
            }

            @include mob {
                width: 220rem;
            }
        }

        &:not(._ready) &Button,
        &._process &Button {
            opacity: 0;
            pointer-events: none;
        }

        &:not(._process) &Prize:not(._result),
        &._complete &Prize:not(._result) {
            opacity: 0 !important;
            pointer-events: none;
        }

        &._complete &Prize._result {
            opacity: 1 !important;
            pointer-events: visible;
        }

        &Prize {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 180rem;
            height: 180rem;
            background: #ff49b8;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            transition: $trans;

            &._result {
                z-index: 100;
                opacity: 0;
                pointer-events: none;
            }

            &:not(._current) {
                opacity: 0;

                &._1 {
                    transform: translate(calc(-50% - 5rem), calc(-50% - 5rem));
                }

                &._2 {
                    transform: translate(calc(-50% + 5rem), calc(-50% + 5rem));
                }

                &._3 {
                    transform: translate(calc(-50% - 5rem), calc(-50% + 5rem));
                }

                &._4 {
                    transform: translate(calc(-50% + 5rem), calc(-50% - 5rem));
                }
            }

            &Image {
                object-fit: contain;
            }

            &Support {
                position: absolute;
                bottom: 0;
                left: 50%;
                z-index: 2;
                padding: 4rem 8rem;
                color: #fff;
                font-weight: 600;
                font-size: 12rem;
                line-height: 1.3;
                white-space: nowrap;
                text-transform: uppercase;
                background: #5d279e;
                border-radius: 7rem;
                transform: translate(-50%, 0);
            }

            &Name {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%,0);
                width: 150%;
                margin-top: 5rem;
                font-weight: 600;
                font-size: 16rem;
                line-height: 1.3;
                text-align: center;
                text-transform: uppercase;
            }

            &Empty {
                color: #fff;
                font-weight: 600;
                font-size: 15rem;
                line-height: 1.4;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}
