.faqQuestion {
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 20rem;

    &__head {
        position: relative;
        padding: 20rem;

        @include mob {
            padding: 14rem;
        }

        &Inner {
            position: relative;
            padding: 0 32rem 0 56rem;

            @include mob {
                padding: 0 32rem 0 46rem;
            }
        }

        &Number {
            position: absolute;
            top: 0;
            left: 0;
            width: 40rem;
            height: 40rem;
            color: #fff;
            font-weight: 500;
            font-size: 20rem;
            background: #ff49b8;
            border-radius: 12rem;

            @include mob {
                width: 34rem;
                height: 34rem;
                font-weight: 600;
                font-size: 18rem;
                border-radius: 10rem;
            }
        }

        &Title {
            padding: 6rem 0;
            color: $colorDark;
            font-weight: 600;
            font-size: 24rem;
            line-height: 1.2;

            @include mob {
                padding: 3rem 0;
                font-size: 20rem;
                line-height: 1.25;
            }
        }

        &Icon {
            position: absolute;
            top: 12rem;
            right: 10rem;
            width: 20rem;
            height: 20rem;
            object-fit: contain;
            transition: $trans;

            @include icon(#10cfc9);
        }
    }

    &._show &__headIcon {
        transform: rotate(180deg);
    }

    &__content {
        transition: $trans;

        &Inner {
            padding: 0 20rem 20rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;

            @include mob {
                padding: 0 14rem 14rem;
                font-size: 18rem;
            }

            & > * + * {
                margin-top: 20rem;
            }
        }

        & p,
        & li {
            color: $colorDark;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;

            @include mob {
                font-size: 18rem;
            }

            & a {
                display: inline;
                color: #ff49b8;
                border-bottom: 1px solid #ff49b8;
            }
        }

        & ol {
            counter-reset: olCounter;
        }

        & li + li {
            margin-top: 12rem;
        }

        & ol li + li {
            margin-top: 20rem;
        }

        & ol li {
            position: relative;
            padding-left: 48rem;
            counter-increment: olCounter;

            &::before {
                position: absolute;
                top: -4rem;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36rem;
                height: 36rem;
                font-weight: 600;
                font-size: 16rem;
                background: #ffd100;
                border-radius: 12rem;
                content: counter(olCounter);

                @include mob {
                    border-radius: 10rem;
                }
            }
        }

        & ul {
            padding-left: 16rem;
        }

        & ul li {
            position: relative;
            padding-left: 14rem;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                color: #ff49b8;
                font-weight: 600;
                font-size: 20rem;
                line-height: 1.3;
                content: "•";
            }
        }

        & img {
            display: block;
            max-width: 100%;
        }
    }
}
