.prizesPage {
    padding-bottom: 6rem;

    @include mob {
        padding-bottom: 16rem;
    }

    &__head {
        margin-bottom: 32rem;

        @include mob {
            margin-bottom: 24rem;
        }

        &Title {
            color: #5d279e;
            font-weight: 600;
            font-size: 24rem;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;

            @include mob {
                font-size: 20rem;
            }
        }

        &Description {
            margin-top: 5rem;
            padding: 4rem 8rem;
            color: $colorDark;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.3;
            text-align: center;
            background: #ffd100;
            border-radius: 6rem;

            @include mob {
                font-size: 17rem;
                border-radius: 10rem;
            }

            & span {
                position: relative;
                z-index: 1;
                display: inline-block;
                padding: 0 9rem;
                color: #fff;
                font-weight: 600;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    width: 22rem;
                    height: 22rem;
                    background: #5d279e;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    content: "";
                }
            }
        }
    }

    &__slider {
        position: relative;
        margin-bottom: 34rem;
        padding: 0 88rem;

        @include mob {
            padding: 0 60rem;
        }

        &Button {
            position: absolute;
            top: 50%;
            z-index: 3;
            width: 48rem;
            height: 48rem;
            margin-top: -24rem;
            background: #e11f26;
            border-radius: 50%;
            transform: translate(0, -50%);
            will-change: transform;

            @include mob {
                width: 40rem;
                height: 40rem;
            }

            &._prev {
                left: 10rem;

                @include mob {
                    left: 7rem;
                }
            }

            &._next {
                right: 10rem;

                @include mob {
                    right: 7rem;
                }
            }

            &Icon {
                width: 20rem;
                height: 20rem;

                @include icon(#fff);

                @include mob {
                    width: 16rem;
                    height: 16rem;
                }
            }
        }

        &Items {
            display: inline-flex;
        }

        &Item {
            width: 180rem;
            margin-right: 48rem;
            transition: $trans;

            @include mob {
                width: 230rem;
                margin-right: 94rem;
            }

            @include desk {
                &:not(._current) {
                    opacity: 0;
                }
            }

            &Head {
                position: relative;
                width: 180rem;
                height: 180rem;
                margin-bottom: 12rem;
                background: #ffd100;
                border-radius: 50%;

                @include mob {
                    width: 230rem;
                    height: 230rem;
                    margin-bottom: 16rem;
                }
            }

            &Image {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &._withInfo &Head {
                margin-bottom: 32rem;
            }

            &Balls {
                position: absolute;
                bottom: 0;
                left: 50%;
                z-index: 2;
                padding: 4rem 8rem;
                color: #fff;
                font-weight: 600;
                font-size: 12rem;
                white-space: nowrap;
                text-transform: uppercase;
                background: #5d279e;
                border-radius: 6rem;
                transform: translate(-50%, 0);

                @include mob {
                    padding: 6rem 10rem;
                    font-size: 14rem;
                    border-radius: 7rem;
                }
            }

            &Info {
                position: absolute;
                right: -20rem;
                bottom: -20rem;
                z-index: 2;
                width: 88rem;
                height: 88rem;
                background: #10cfc9;
                border-radius: 50%;

                @include mob {
                    right: -14rem;
                    bottom: -14rem;
                    width: 98rem;
                    height: 98rem;
                }

                &Inner {
                    color: #fff;
                    font-weight: 600;
                    font-size: 12rem;
                    line-height: 1.3;
                    text-align: center;

                    @include mob {
                        font-size: 13rem;
                    }

                    & span {
                        position: relative;
                        z-index: 1;
                        display: inline-block;
                        padding: 0 3rem;
                        color: #fff;
                        font-weight: 600;

                        &::before {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            z-index: -1;
                            width: 18rem;
                            height: 18rem;
                            background: #5d279e;
                            border-radius: 50%;
                            transform: translate(-50%, -50%);
                            content: "";
                        }
                    }
                }
            }

            &Description {
                color: $colorDark;
                font-weight: 600;
                font-size: 16rem;
                line-height: 1.3;
                text-align: center;
                text-transform: uppercase;

                @include mob {
                    font-size: 18rem;
                }
            }
        }
    }

    &__mainPrize {
        width: 680rem;
        height: 330rem;

        @include mob {
            width: 100%;
            height: 430rem;
            padding: 0 12rem;
        }
    }
}
