.input {
    position: relative;
    width: 100%;
    height: 100%;
    background: #f3f3f3;
    border-radius: 14rem;

    &._white {
        background: #fff;
    }

    @include mob {
        border-radius: 12rem;
    }

    &._static {
        display: flex;
    }

    &__field {
        width: 100%;
        height: 100%;
        color: $colorDark;
        font-weight: 500;
        line-height: 1.4;
        cursor: text;
        resize: none;
    }

    &._static &__field {
        position: relative;
        height: auto;
        margin: auto 0;
        overflow: hidden;
        line-height: 1.5;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__support {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $colorDark;
        font-weight: 500;
        line-height: 1.4;
    }

    &._area &__support {
        align-items: flex-start;
    }

    &._focus &__support,
    &:not(._empty) &__support {
        opacity: 0;
        pointer-events: none;
    }

    &__field,
    &__support {
        padding: 20rem;
        font-size: 20rem;
        line-height: 1.3;
        cursor: text;
        transition: $trans;
    }

    &._emptySize &__field,
    &._emptySize &__support {
        padding: 0;
    }

    &._center &__field,
    &._center &__support {
        justify-content: center;
        text-align: center;
    }

    &._error &__field,
    &._error &__support {
        color: red;
        font-weight: 600;
    }
}
