.index5kaProducts {
    position: relative;
    z-index: 1;
    padding-bottom: 50rem;

    &__decor,
    &__contentDecor {
        position: absolute;
        z-index: -1;
        pointer-events: none;
    }

    &__decor._1 {
        top: 126rem;
        left: -18rem;
        width: 105rem;
        height: 62rem;
        animation: kaProductsDecor1 2.5s infinite ease-in-out;

        @include bg("/media/5ka/productDecor-6.svg", contain, center);

        @keyframes kaProductsDecor1 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(20rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: -30rem;
            left: -60rem;
        }
    }

    &__decor._2 {
        top: 110rem;
        left: 69rem;
        width: 52rem;
        height: 52rem;
        animation: kaProductsDecor2 2.5s infinite linear;

        @include bg("/media/5ka/productDecor-7.svg", contain, center);

        @keyframes kaProductsDecor2 {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(-360deg);
            }
        }

        @include mob {
            top: -35rem;
            left: 22rem;
            width: 44rem;
            height: 44rem;
        }
    }

    &__decor._3 {
        bottom: -50rem;
        left: 240rem;
        width: 131rem;
        height: 122rem;

        @include bg("/media/5ka/productDecor-8.svg", contain, center);

        @include mob {
            bottom: -230rem;
            left: -60rem;
        }
    }

    &__decor._4 {
        top: -4rem;
        right: -52rem;
        display: flex;
        width: 112rem;
        height: 112rem;
        background: repeating-linear-gradient(#e11f26, #e11f26 5.5rem, #ff1c24 5.5rem, #ff1c24 7.5rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);
        animation: kaProductsDecor4 4s infinite linear;

        &::before {
            z-index: 2;
            display: block;
            width: 52rem;
            height: 52rem;
            margin: auto;
            background: #ffd100;
            border-radius: 50%;
            content: "";
        }

        @keyframes kaProductsDecor4 {
            0% {
                background-position: 0 0;
            }

            100% {
                background-position: 112rem 112rem;
            }
        }

        @include mob {
            top: -80rem;
            right: -66rem;
        }
    }

    &__decor._5 {
        right: -49rem;
        bottom: -52rem;
        display: flex;
        width: 126rem;
        height: 126rem;
        background: repeating-linear-gradient(#de261f, #de261f 6rem, #ff49b8 6rem, #ff49b8 9rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);
        animation: kaProductsDecor5 4s infinite linear;

        &::before {
            z-index: 2;
            display: block;
            width: 58rem;
            height: 58rem;
            margin: auto;
            background: #de261f;
            border-radius: 50%;
            content: "";
        }

        @keyframes kaProductsDecor5 {
            0% {
                background-position: 0 0;
            }

            100% {
                background-position: 126rem 126rem;
            }
        }
    }

    &__inner {
        align-items: center;

        @include mob {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 30rem;
        color: #fff;
        font-weight: 600;
        font-size: 32rem;
        line-height: 1.3;

        @include mob {
            font-size: 26rem;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        padding: 60rem 0;
        overflow: hidden;
        background: #ff1c24;
        border-radius: 64rem;

        @include mob {
            padding: 0 18rem;
            background: none;
            border-radius: 0;
        }
    }

    &__contentDecor {
        @include mob {
            display: none;
        }
    }

    &__contentDecor._1 {
        bottom: 98rem;
        left: 42rem;
        width: 22rem;
        height: 18rem;
        animation: kaProductsContentDecor1 2s infinite ease-in-out;

        @include bg("/media/5ka/productDecor-1.svg", contain, center);

        @keyframes kaProductsContentDecor1 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(-20rem, 20rem);
                opacity: 0;
            }
        }
    }

    &__contentDecor._2 {
        top: 115rem;
        left: 44rem;
        width: 20rem;
        height: 18rem;
        animation: kaProductsContentDecor2 2s infinite ease-in-out;

        @include bg("/media/5ka/productDecor-2.svg", contain, center);

        @keyframes kaProductsContentDecor2 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(-20rem, -10rem);
                opacity: 0;
            }
        }
    }

    &__contentDecor._3 {
        top: 61rem;
        left: 57rem;
        width: 15rem;
        height: 20rem;
        animation: kaProductsContentDecor3 2s infinite ease-in-out;

        @include bg("/media/5ka/productDecor-3.svg", contain, center);

        @keyframes kaProductsContentDecor3 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(-10rem, -20rem);
                opacity: 0;
            }
        }
    }

    &__contentDecor._4 {
        top: 58rem;
        right: 36rem;
        width: 39rem;
        height: 41rem;
        animation: kaProductsContentDecor4 2s infinite ease-in-out;

        @include bg("/media/5ka/productDecor-4.svg", contain, center);

        @keyframes kaProductsContentDecor4 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(20rem, -20rem);
                opacity: 0;
            }
        }
    }

    &__contentDecor._5 {
        top: 138rem;
        right: 70rem;
        width: 23rem;
        height: 18rem;
        animation: kaProductsContentDecor5 2s infinite ease-in-out;

        @include bg("/media/5ka/productDecor-5.svg", contain, center);

        @keyframes kaProductsContentDecor5 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(30rem, -5rem);
                opacity: 0;
            }
        }
    }

    &__contentDecor._6 {
        right: 28rem;
        bottom: 68rem;
        width: 16rem;
        height: 16rem;
        border: 4rem solid #ff49b8;
        border-radius: 50%;
        animation: kaProductsContentDecor6 2s infinite ease-in-out;

        @keyframes kaProductsContentDecor6 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(16rem, 16rem);
                opacity: 0;
            }
        }
    }
}
