$circleCount: 12;
$animationDuration: 1.2s;

.loader {
    position: relative;
    width: 100%;
    height: 100%;

    &__item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: lds-spinner 0.8s linear infinite;

        &Inner {
            // width: 8%;
            // min-width: 1px;
            width: 2px;
            max-width: 2px;
            height: 29%;
            margin: 0 auto;
            background: #fff;
            border-radius: 360px;
        }
    }

    &._main &__itemInner {
        background: $colorMain;
    }

    &__item:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -0.7s;
    }

    &__item:nth-child(2) {
        transform: rotate(45deg);
        animation-delay: -0.6s;
    }

    &__item:nth-child(3) {
        transform: rotate(90deg);
        animation-delay: -0.5s;
    }

    &__item:nth-child(4) {
        transform: rotate(135deg);
        animation-delay: -0.4s;
    }

    &__item:nth-child(5) {
        transform: rotate(180deg);
        animation-delay: -0.3s;
    }

    &__item:nth-child(6) {
        transform: rotate(225deg);
        animation-delay: -0.2s;
    }

    &__item:nth-child(7) {
        transform: rotate(270deg);
        animation-delay: -0.1s;
    }

    &__item:nth-child(8) {
        transform: rotate(315deg);
        animation-delay: -0s;
    }

    @keyframes lds-spinner {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
}
