.merch {
    position: relative;
    z-index: 1;
    border: 5rem solid #ffd100;
    border-radius: 20rem;

    @include mob {
        border-radius: 18rem;
    }

    &__inner {
        position: relative;
        z-index: 1;
    }

    &__head {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        padding: 6rem;

        &Info {
            position: absolute;
            top: 50%;
            right: 18rem;
            z-index: 3;
            width: 20rem;
            height: 20rem;
            color: #fff;
            font-weight: 600;
            font-size: 12rem;
            background: #ff49b8;
            border-radius: 50%;
            transform: translate(0, -50%);
        }
    }

    &__search {
        position: relative;
        z-index: 1;
        height: 48rem;

        & .input__field,
        & .input__support {
            padding: 12rem;
            font-size: 16rem;
        }
    }

    &__content {
        padding-top: 68rem;
        padding-bottom: 28rem;
    }

    &__slider {
        position: relative;
        z-index: 1;
        margin-bottom: 20rem;
        overflow: hidden;

        &Button {
            position: absolute;
            top: 50%;
            z-index: 3;
            width: 36rem;
            height: 36rem;
            background: #f3f3f3;
            border-radius: 50%;
            transform: translate(0, -50%);
            transition: $trans;

            @include mob {
                width: 40rem;
                height: 40rem;
            }

            &._prev {
                left: 30rem;

                @include mob {
                    left: 10rem;
                }
            }

            &._next {
                right: 30rem;

                @include mob {
                    right: 10rem;
                }
            }

            &Icon {
                width: 10rem;
                height: 16rem;

                @include icon(#e11f26);

                @include mob {
                    width: 12rem;
                    height: 18rem;
                }
            }

            &._prev &Icon {
                margin-left: -1rem;
            }

            &._next &Icon {
                margin-left: 1rem;
            }
        }

        &:not(._active) &Button {
            opacity: 0;
            pointer-events: none;
        }

        &Support {
            position: absolute;
            top: 26rem;
            right: 6rem;
            z-index: -1;
            color: #ff49b8;
            font-weight: 500;
            font-size: 12rem;
            line-height: 1.3;
            transition: $trans;
            pointer-events: none;

            @include mob {
                top: 0;
                font-size: 14rem;
            }
        }

        &:not(._active) &Support {
            opacity: 0;
            pointer-events: none;
        }

        &Items {
            display: inline-flex;
        }

        &Item {
            position: relative;
            width: 382rem;
            height: 218rem;

            @include mob {
                width: 320rem;
                height: 208rem;
            }

            &Name {
                position: absolute;
                top: 32rem;
                left: 50%;
                z-index: 3;
                width: 93rem;
                height: 50rem;
                transform: translate(-50%, 0);
                pointer-events: none;

                &Inner {
                    color: #222221;
                    font-weight: 600;
                    font-size: 9rem;
                    white-space: nowrap;
                    text-transform: uppercase;

                    & span {
                        color: #fff;
                        font-weight: 600;
                    }
                }
            }

            &Image {
                position: relative;
                z-index: 1;
                display: block;
                object-fit: contain;
                user-select: none;
                pointer-events: none;
            }
        }
    }

    &__title {
        margin-top: auto;
        font-weight: 700;
        font-size: 16rem;
        line-height: 1.3;
        text-align: center;
        text-transform: uppercase;

        @include mob {
            font-weight: 600;
            font-size: 18rem;
        }
    }

    &__count {
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 2;
        padding: 4rem 12rem;
        color: #fff;
        font-weight: 600;
        font-size: 16rem;
        line-height: 1.3;
        white-space: nowrap;
        text-transform: uppercase;
        background: #5d279e;
        border-radius: 8rem;
        transform: translate(-50%, 50%);
    }
}
