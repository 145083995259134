.prize {
    position: relative;
    z-index: 1;
    padding: 20rem;
    background: #fff;
    border-radius: 16rem;

    @include mob {
        padding: 16rem;
    }

    &__head {
        position: relative;
        margin-bottom: 18rem;
    }

    &__status {
        padding: 5rem 8rem 4rem;
        color: #fff;
        font-weight: 500;
        font-size: 14rem;
        line-height: 1.2;
        text-transform: uppercase;
        background: #10cfc9;
        border-radius: 5rem;

        @include mob {
            font-weight: 600;
            font-size: 16rem;
        }
    }

    &._SENDING &__status,
    &._SENDING &__countInner {
        background: #b8b8b8;
    }

    &._SENT &__status,
    &._SENT &__countInner {
        background: #5d279e;
    }

    &._COMPLETED &__status,
    &._COMPLETED &__countInner {
        background: #71c200;
    }

    &._SENDING &__countSupport {
        color: #b8b8b8;
    }

    &._SENT &__countSupport {
        color: #5d279e;
    }

    &._COMPLETED &__countSupport {
        color: #71c200;
    }

    &__count {
        position: absolute;
        top: 0;
        right: 0;

        &Inner {
            width: 48rem;
            height: 48rem;
            margin-bottom: 2rem;
            color: #fff;
            font-weight: 600;
            font-size: 24rem;
            background: #10cfc9;
            border-radius: 50%;

            @include mob {
                width: 54rem;
                height: 54rem;
                font-size: 26rem;
            }
        }

        &Support {
            color: #10cfc9;
            font-weight: 600;
            font-size: 16rem;
        }
    }

    &__preview {
        position: relative;
        margin-bottom: 21rem;

        &Inner {
            position: relative;
            width: 180rem;
            height: 180rem;
            background: #ffd100;
            border-radius: 50%;
        }

        &Image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &Button {
            position: absolute;
            bottom: -7rem;
            left: 50%;
            z-index: 2;
            padding: 13rem 16rem 12rem;
            white-space: nowrap;
            background: #fff;
            border: 2rem solid #10cfc9;
            border-radius: 8rem;
            transform: translate(-50%, 0);

            & span {
                display: inline-block;
                color: #10cfc9;
                font-weight: 600;
                font-size: 14rem;
                line-height: 0.8;
                text-transform: uppercase;
                border-bottom: 1px solid #10cfc9;

                @include mob {
                    font-size: 16rem;
                }
            }
        }
    }

    &__name {
        max-width: 90%;
        font-weight: 600;
        font-size: 16rem;
        line-height: 1.3;
        text-align: center;
        text-transform: uppercase;

        @include mob {
            max-width: 100%;
            font-size: 18rem;
        }
    }
}
