.select {
    position: relative;

    &__view {
        position: relative;
        padding: 16rem 20rem;
        background: #fff;
        border-radius: 14rem;

        &Inner {
            padding-right: 28rem;
            overflow: hidden;
            color: $colorDark;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: $trans;
        }

        &Icon {
            position: absolute;
            top: 50%;
            right: 20rem;
            z-index: 2;
            width: 20rem;
            height: 20rem;
            transform: translate(0, -50%);
            transition: $trans;
        }
    }

    &._show &__viewIcon {
        transform: translate(0, -50%) rotate(180deg);
    }

    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 2;
        margin-top: 4rem;
        padding: 10rem;
        background: #fff;
        border-radius: 12rem;
        box-shadow: 0 16rem 16rem 0 rgba(#000, 0.15);

        &Inner {
            position: relative;
            z-index: 2;
            max-height: 250rem;
            padding: 10rem;
            overflow: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 6rem;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #d9d9d9;
                border-radius: 20rem;
            }
        }

        &Item {
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
            transition: $trans;

            &:hover {
                color: #ff49b8;
            }

            & + & {
                margin-top: 14rem;
            }
        }
    }
}
