.anketForm {
    position: relative;
    z-index: 1;
    padding: 40rem 48rem;
    overflow: hidden;
    background: #fff;
    border-radius: 32rem;
    transition: $trans;

    &._success {
        height: 286rem !important;

        @include mob {
            height: 220rem !important;
        }
    }

    @include mob {
        padding: 18rem 12rem;
        border-radius: 20rem;
    }

    &__success {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        padding: 40rem 48rem;
        background: #fff;

        @include mob {
            padding: 20rem;
        }

        &Inner {
            padding: 20rem 60rem;
            background: #ffd100;
            border-radius: 12rem;

            @include mob {
                padding: 14rem 24rem;
                border-radius: 14rem;
            }
        }

        &Title {
            color: #5d279e;
            font-weight: 600;
            font-size: 24rem;
            line-height: 1.2;
            text-align: center;

            @include mob {
                font-size: 22rem;
            }
        }

        &Description {
            margin-top: 12rem;
            color: #2f2922;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;
        }
    }

    &__field {
        position: relative;

        & + & {
            margin-top: 16rem;
        }

        &Support {
            margin-bottom: 5rem;
            color: #5d279e;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.2;
        }

        &Box {
            height: 60rem;

            @include mob {
                height: 56rem;
            }
        }

        &._textarea &Box {
            height: 160rem;

            @include mob {
                height: 150rem;
            }
        }

        &List {
            position: absolute;
            top: 100%;
            z-index: 2;
            max-height: 200rem;
            margin-top: 6rem;
            padding: 10rem 0;
            overflow: hidden;
            overflow-y: auto;
            background: #fff;
            border-radius: 14rem;
            box-shadow: 0 0 10rem rgba(#000, 0.1);

            &Item {
                padding: 6rem 24rem;
                color: $colorDark;
                font-size: 20rem;
                line-height: 1.3;

                & span {
                    color: #5d279e;
                }
            }
        }
    }

    &__support {
        margin-top: 20rem;
        color: #5d279e;
        font-size: 16rem;
        line-height: 1.3;
    }

    &__error {
        width: 100%;
        margin-top: 20rem;

        &:not(._empty) {
            margin-bottom: 20rem;
        }
    }

    &__button {
        height: 68rem;

        @include mob {
            width: 100%;
        }
    }
}
