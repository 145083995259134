.profile {
    padding: 0 26rem;

    @include mob {
        padding: 10rem 0 0;
    }

    &__main {
        margin-bottom: 48rem;
        padding: 20rem;
        background: #fcfcfc;
        border-radius: 20rem;

        @include mob {
            padding: 20rem 10rem;
        }

        &Blocks {
            display: flex;
            margin-bottom: 34rem;

            @include mob {
                flex-direction: column;
            }
        }

        &Block {
            @include mob {
                width: 100%;

                &._info {
                    margin-bottom: 28rem;
                    padding-bottom: 22rem;
                    border-bottom: 2rem solid #f3f3f3;
                }
            }
        }

        &Block._score {
            align-items: flex-end;
            margin-left: auto;
            padding-top: 10rem;

            @include mob {
                flex-direction: row;
                align-items: center;
                margin-left: 0;
                padding-top: 14rem;
            }
        }

        &Name {
            display: flex;
            align-items: center;
            margin-bottom: 14rem;
            font-weight: 600;
            font-size: 24rem;
            line-height: 1.2;

            @include mob {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 28rem;
                font-size: 26rem;
            }
        }

        &Copy {
            position: relative;
            margin-left: 13rem;
            padding: 6rem 36rem 6rem 12rem;
            font-weight: 600;
            font-size: 16rem;
            line-height: 1.3;
            background: #ffd100;
            border-radius: 8rem;

            @include mob {
                margin: 10rem 0 0;
                padding: 7rem 40rem 7rem 12rem;
                font-size: 18rem;
            }

            &Icon {
                position: absolute;
                top: 50%;
                right: 10rem;
                width: 17rem;
                height: 17rem;
                transform: translate(0, -50%);

                @include mob {
                    width: 19rem;
                    height: 19rem;
                }
            }

            &Success {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                color: #ff49b8;
                font-weight: 600;
                font-size: 16rem;
                background: inherit;
                border-radius: inherit;
            }
        }

        &Links {
            @include mob {
                width: 100%;
            }
        }

        &Link {
            position: relative;
            display: inline-block;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1;
            border-bottom: 1px solid transparent;
            transition: $trans;

            &._loading {
                margin-right: 32rem;
            }

            @include mob {
                font-size: 18rem;
            }

            &._pink {
                color: #ff49b8;
                border-bottom-color: #ff49b8;
            }

            &._purple {
                color: #5d279e;
                border-bottom-color: #5d279e;

                & .loader__itemInner {
                    background: #5d279e;
                }
            }

            &._blue {
                color: #10cfc9;
                border-bottom-color: #10cfc9;
            }

            & + & {
                margin-left: 14rem;

                @include mob {
                    margin-left: auto;
                }
            }

            &Loader {
                left: 100%;
                z-index: 2;
                width: 20rem;
                height: 20rem;
                margin-left: 8rem;

                & ._LOADERITEM {
                    width: 20rem;
                    height: 20rem;
                }
            }
        }

        &Block._score &Link {
            @include mob {
                margin-left: auto;
            }
        }

        &:not(._ready) &Score {
            opacity: 0;
        }

        &Score {
            margin-bottom: 12rem;
            color: #5d279e;
            font-weight: 600;
            font-size: 20rem;
            text-transform: uppercase;
            transition: $trans;

            @include mob {
                margin-bottom: 0;
            }

            &Count {
                position: relative;
                z-index: 1;
                display: inline-block;
                min-width: 52rem;
                padding: 0 6rem;
                color: #fff;
                font-weight: 700;
                font-size: 22rem;
                text-align: center;

                @include mob {
                    min-width: 60rem;
                    font-size: 24rem;
                }

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    width: 100%;
                    height: 52rem;
                    background: #5d279e;
                    border-radius: 360rem;
                    transform: translate(-50%, -50%);
                    content: "";

                    @include mob {
                        height: 60rem;
                    }
                }
            }
        }

        &Buttons {
            display: flex;

            @include mob {
                flex-direction: column-reverse;
            }

            &Inner {
                display: flex;

                &._invite {
                    margin: 0 0 0 auto;

                    @include mob {
                        margin: 0;
                        margin-bottom: 28rem;
                        padding-bottom: 28rem;
                        border-bottom: 2rem solid #f3f3f3;
                    }
                }

                @include mob {
                    flex-direction: column;
                }
            }
        }

        &Button {
            height: 68rem;
            margin-right: 12rem;

            @include mob {
                margin-right: 0;
                white-space: nowrap;

                & + & {
                    margin-top: 14rem;
                }
            }
        }
    }

    &__pages {
        &TabsWrapper {
            width: 100%;

            @include mob {
                overflow: hidden;
                overflow-x: auto;
            }
        }

        &Tabs {
            display: flex;
            justify-content: space-between;
        }

        &TabsWrapper &Tabs {
            display: inline-flex;
        }

        &Tab {
            width: calc(calc(100% / 3) - 3rem);
            padding: 19rem 20rem 18rem;
            color: #fff;
            font-weight: 600;
            font-size: 16rem;
            white-space: nowrap;
            text-transform: uppercase;
            background: #ff49b8;
            border-radius: 14rem 14rem 0 0;
            transition: $trans;

            &._auto {
                width: 100%;
            }

            &._current {
                color: #5d279e;
                background: #fff;
            }

            &Wrapper {
                flex: 1 auto;
                width: auto;
                padding: 0 2rem;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        &Items {
            position: relative;
            z-index: 1;
            width: 100%;
            overflow: hidden;
            background: #fff;
            border-radius: 0 0 20rem 20rem;
        }

        &Item {
            width: 100%;
            padding: 24rem;

            &:not([data-show])._prev {
                transform: translate(-32rem, 0);
            }

            &:not([data-show])._next {
                transform: translate(32rem, 0);
            }

            @include mob {
                padding: 18rem 12rem;
            }
        }
    }

    &__block {
        position: relative;
        z-index: 1;
        padding: 35rem 36rem;
        overflow: hidden;
        background: #fcdbc6;
        border-radius: 20rem;

        @include mob {
            padding: 18rem 10rem;
        }

        &._codes,
        &._prizes {
            &::before {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                content: "";
            }
        }

        &._codes::before {
            @include bg("/media/page-back.svg", 100% auto, center calc(100% + 170rem));
        }

        &._prizes::before {
            @include bg("/media/page-back.svg", 100% auto, center calc(100% + 60rem));
        }

        & + & {
            margin-top: 36rem;
        }

        &Head {
            margin-bottom: 28rem;
        }

        &Title {
            text-align: center;
        }

        &Description {
            margin-top: 5rem;
            padding: 4rem 8rem;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.3;
            text-align: center;
            background: #fff;
            border-radius: 6rem;

            @include mob {
                max-width: 87%;
                font-size: 17rem;
                border-radius: 10rem;
            }

            & span {
                position: relative;
                z-index: 1;
                display: inline-block;
                padding: 0 9rem;
                color: #fff;
                font-weight: 600;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    width: 22rem;
                    height: 22rem;
                    background: #5d279e;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    content: "";
                }
            }
        }

        &DynamicButtonWrapper {
            white-space: nowrap;

            & .list__item {
                display: flex;
                justify-content: center;
                width: 100%;
                padding-top: 18rem;
            }

            & .list__itemBox {
                width: auto;
            }
        }

        &Button {
            margin-top: 26rem;

            @include mob {
                margin-bottom: 20rem;
            }
        }

        &Raffles {
            display: flex;
            justify-content: space-between;

            @include mob {
                flex-direction: column;
                justify-content: flex-start;
            }
        }

        &Raffle {
            width: calc(50% - 18rem);

            @include mob {
                width: 100%;

                & + & {
                    margin-top: 18rem;
                }
            }
        }

        &Prizes {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            width: 100%;
            padding: 10rem 24rem 0;

            @include mob {
                flex-wrap: wrap;
                width: 100%;
                padding-top: 0;
            }
        }

        &Prize {
            width: 170rem;

            @include mob {
                width: 50%;
                margin-bottom: 20rem;
            }

            &Head {
                position: relative;
                width: 170rem;
                height: 170rem;
                margin-bottom: 26rem;

                @include mob {
                    width: 150rem;
                    height: 150rem;
                    margin-bottom: 16rem;
                }
            }

            &Image {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 135%;
                height: 135%;
                transform: translate(-50%, -50%);
                transition: $trans;
                will-change: transform;

                @include mob {
                    width: 115%;
                    height: 115%;
                }
            }

            &:hover &Image {
                transform: translate(-50%, -50%) scale(1.075);
            }

            &Content {
                font-weight: 600;
                font-size: 16rem;
                line-height: 1.3;
                text-align: center;
                text-transform: uppercase;
            }
        }

        &MainPrizes {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 24rem);
            margin-bottom: -24rem;

            @include mob {
                flex-flow: column nowrap;
                width: 100%;
                margin-bottom: 0;
            }
        }

        &MainPrize {
            width: calc(100% / 3);
            margin-bottom: 24rem;
            padding: 0 12rem;

            @include mob {
                width: 100%;
                margin-bottom: 0;
                padding: 0;

                & + & {
                    margin-top: 24rem;
                }
            }
        }
    }
}
