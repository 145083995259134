.product {
    position: relative;
    z-index: 1;
    height: 100%;
    -webkit-transform-style: preserve-3d;
    transition: $trans;
    will-change: transform;

    &._rotate {
        transform: rotateY(-180deg);
    }

    &__main {
        position: relative;
        z-index: 2;
        height: 100%;
        padding: 24rem 16rem;
        overflow: hidden;
        background: #fff;
        border-radius: 20rem;
        will-change: transform;

        @include mob {
            border-radius: 24rem;
        }
    }

    &._rotate &__main {
        // transform: rotateY(180deg);
    }

    &._rotateComplete &__main {
        opacity: 0;
        pointer-events: none;
    }

    &__back {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        padding: 20rem 16rem;
        background: #ff49b8;
        border-radius: 20rem;
        transform: rotateY(-180deg);
        will-change: transform;

        @include mob {
            border-radius: 24rem;
        }

        &Text {
            color: #fff;
            font-weight: 500;
            font-size: 14rem;
            line-height: 1.3;
            text-align: center;

            @include mob {
                font-size: 16rem;
            }

            &._main {
                color: #ffd100;
                font-weight: 600;
                font-size: 16rem;

                @include mob {
                    font-size: 18rem;
                }
            }

            & + & {
                margin-top: 8rem;
            }
        }

        &Button {
            margin-top: auto;
        }
    }

    &:not(._rotateComplete) &__back {
        opacity: 0;
        pointer-events: none;
    }

    &__slider {
        position: relative;
        height: 260rem;
        margin-bottom: 10rem;

        @include mob {
            height: 290rem;
            margin-bottom: 15rem;
        }

        &Button {
            position: absolute;
            top: 50%;
            z-index: 3;
            width: 36rem;
            height: 36rem;
            margin-top: -12rem;
            background: #f3f3f3;
            border-radius: 50%;
            transform: translate(0, -50%);
            will-change: transform;

            @include mob {
                width: 40rem;
                height: 40rem;
                margin-top: -20rem;
            }

            &._prev {
                left: 18rem;

                @include mob {
                    left: 28rem;
                }
            }

            &._next {
                right: 18rem;

                @include mob {
                    right: 28rem;
                }
            }

            &Icon {
                width: 10rem;
                height: 15rem;

                @include icon(#e11f26);

                @include mob {
                    width: 12rem;
                    height: 18rem;
                }
            }

            &._prev &Icon {
                margin-left: -1rem;
            }

            &._next &Icon {
                margin-left: 1rem;
            }
        }

        &Items {
            position: relative;
            z-index: 2;
            display: inline-flex;
        }

        &Item {
            width: 250rem;

            @include mob {
                width: 300rem;
            }

            &Image {
                width: 100%;
                height: 218rem;
                margin-bottom: 14rem;
                object-fit: contain;

                @include mob {
                    height: 260rem;
                    margin-bottom: 8rem;
                }
            }

            &Tabs {
                align-items: center;
            }

            &Tab {
                padding: 2rem 8rem;
                font-weight: 600;
                font-size: 14rem;
                line-height: 1.2;
                background: #ffd100;
                border-radius: 8rem;

                &._new {
                    color: #fff;
                    background: #71c200;
                }

                @include mob {
                    padding: 3rem 9rem;
                    font-size: 16rem;
                    line-height: 1.3;
                    border-radius: 9rem;
                }

                & + & {
                    margin-left: 4rem;
                }
            }
        }
    }

    &__name {
        display: flex;
        align-items: center;
        height: 38rem;
        margin-bottom: 15rem;
        font-weight: 600;
        font-size: 16rem;
        line-height: 1.3;
        text-align: center;

        @include mob {
            height: 46rem;
            margin-bottom: 15rem;
            font-size: 18rem;
        }
    }

    &__button {
        padding: 6rem 10rem;
        font-weight: 600;
        font-size: 12rem;
        line-height: 1.2;
        text-transform: uppercase;
        border: 2rem solid transparent;
        border-radius: 8rem;

        @include mob {
            padding: 8rem 12rem;
            font-size: 14rem;
            border-radius: 10rem;
        }

        &._main {
            color: #fff;
            background: #ff49b8;
        }
    }

    &__back &__button {
        color: #fff;
        border-color: #fff;
    }
}
