.winners {
    padding: 4rem 24rem 0;

    @include mob {
        padding: 0;
    }

    &__filter {
        position: relative;
        z-index: 2;
        display: flex;
        margin-bottom: 16rem;

        @include mob {
            flex-direction: column;
            padding: 0 16rem;
        }

        &Block {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;

            @include mob {
                flex-direction: column;
                width: 100%;

                & + & {
                    margin-top: 20rem;
                }
            }

            &._date {
                z-index: 2;
            }

            @include desk {
                &._date {
                    width: 45%;
                }

                &._search {
                    width: calc(100% - 45%);
                    padding-left: 32rem;
                }
            }

            &Field {
                height: 60rem;

                @include mob {
                    width: 100%;
                }
            }

            @include desk {
                &._date &Field {
                    width: 264rem;
                }

                &._date &Support {
                    width: calc(100% - 264rem);
                }
            }

            &._search &Field {
                position: relative;
                width: 344rem;

                @include mob {
                    width: 100%;
                }

                &Icon {
                    position: absolute;
                    top: 50%;
                    left: 20rem;
                    z-index: 2;
                    width: 20rem;
                    height: 20rem;
                    transform: translate(0, -50%);

                    @include mob {
                        left: 18rem;
                        width: 18rem;
                        height: 18rem;
                    }
                }

                & .input__support,
                & .input__field {
                    padding-left: 54rem;

                    @include mob {
                        padding-left: 48rem;
                    }
                }
            }

            @include desk {
                &._search &Support {
                    width: calc(100% - 344rem);
                }
            }

            &Support {
                padding-left: 16rem;
                color: $colorDark;
                font-weight: 500;
                font-size: 16rem;
                line-height: 1.2;

                @include mob {
                    width: 100%;
                    margin-top: 8rem;
                    padding-left: 0;
                    line-height: 1.3;
                }
            }
        }
    }

    &__table {
        position: relative;
        z-index: 1;
    }

    &__foot {
        white-space: nowrap;

        & .list__item {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: 32rem;
        }

        & .list__itemBox {
            width: auto;
        }
    }
}
