.field {
    width: 100%;
    height: 100%;

    &__support {
        margin-bottom: 4rem;
        color: #5d279e;
        font-weight: 500;
        font-size: 20rem;
        line-height: 1.3;
    }

    &__box {
        width: 100%;
        height: 60rem;

        @include mob {
            height: 54rem;
        }
    }

    &._textarea &__box {
        height: 120rem;
    }
}
