.page {
    position: relative;
    z-index: 1;
    padding: 90rem 0 0;
    overflow: hidden;
    overflow-y: auto;
    transition: $trans;

    &._hide {
        opacity: 0;
    }

    @include mob {
        padding: 68rem 0 0;
    }

    [data-id="game"] &__innerBox {
        padding: 32rem 84rem;

        @include mob {
            width: 100%;
            padding: 0;
        }

        @include desk {
            box-shadow: 0 38rem 0 0 rgba(#7d0028, 0.3);

            &::before,
            &::after {
                position: absolute;
                top: 50%;
                left: auto;
                z-index: -1;
                width: 20rem;
                height: 194rem;
                background: rgba(#c3090f, 0.1);
                border-radius: 24rem;
                transform: translate(0, -50%);
                content: "";
            }

            &::before {
                left: 32rem;
            }

            &::after {
                right: 32rem;
            }
        }
    }

    [data-id="game"] &__inner {
        @include mob {
            padding-bottom: 0;
        }
    }

    &__inner {
        position: relative;
        min-height: 100%;
        padding: 30rem 0 100rem;

        @include mob {
            padding: 0 0 50rem;
        }

        @include desk {
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                content: "";

                @include bg("/media/page-decor.svg", 100% auto, center top, repeat-y);
            }
        }

        &Box {
            position: relative;
            z-index: 1;
            padding: 32rem 32rem 52rem;
            overflow: hidden;
            background: #fcdbc6;
            border-radius: 32rem;

            @include mob {
                padding: 20rem 8rem 44rem;
                border-radius: 24rem;
            }

            &._empty {
                padding-right: 0;
                padding-left: 0;

                @include mob {
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            &::before {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                content: "";

                @include bg("/media/page-back.svg", 100% auto, center calc(100% + 150rem));

                @include mob {
                    top: 0;
                    right: 0;
                    left: auto;
                    width: calc(100% - 20rem);
                    height: 100%;

                    @include bg("/media/page-back-mob.svg", 100% auto, right bottom);
                }
            }
        }

        &Content {
            width: 610rem;
            margin: 0 auto;

            @include mob {
                width: 100%;
            }
        }
    }

    &._faq &__innerBox::before {
        background-position: center 80%;
    }

    &._profile &__innerBox {
        padding-bottom: 26rem;

        @include mob {
            padding-bottom: 0;
        }

        &::before {
            display: none;
        }
    }

    &._anketMap &__innerBox {
        padding-bottom: 0;
    }

    &._hide &__content {
        opacity: 0;
    }

    &__content {
        transition: $trans;

        &._minH {
            min-height: 500rem;
        }

        &Inner {
            transition: $trans;

            &._hide {
                opacity: 0;
            }
        }
    }

    &__close {
        position: absolute;
        top: 22rem;
        right: 22rem;
        z-index: 2;
    }

    &__head {
        margin-bottom: 32rem;

        @include mob {
            margin-bottom: 20rem;
        }
    }

    &__title {
        color: #5d279e;
        font-weight: 600;
        font-size: 32rem;
        line-height: 1.2;
        text-align: center;

        @include mob {
            font-size: 26rem;
        }
    }

    &__description {
        margin-top: 6rem;
        color: #5d279e;
        font-weight: 500;
        font-size: 20rem;
        line-height: 1.3;
        text-align: center;

        @include mob {
            margin-top: 12rem;
            font-size: 18rem;
        }

        &Link {
            display: inline-block;
            color: #ff49b8;
            font-weight: 500;
            border-bottom: 1px solid #ff49b8;
        }
    }

    &__map {
        width: 100%;
        padding: 0 30rem 30rem;

        @include mob {
            padding: 0;
        }

        &Inner {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 500rem;
            overflow: hidden;
            background: #f0f0f0;
            border: 2rem solid #fff;
            border-radius: 12rem;

            & #map {
                width: 100%;
                height: 100%;
            }

            .ymaps-2-1-79-balloon__layout h3 {
                font-weight: 600;
                font-size: 20px;
                font-family: Onest;
                line-height: 1.3;
            }

            .ymaps-2-1-79-balloon__layout p + p {
                margin-top: 6px;
            }

            .ymaps-2-1-79-balloon__layout ul {
                margin-bottom: 10px;
            }

            .ymaps-2-1-79-balloon__layout p,
            .ymaps-2-1-79-balloon__layout li {
                font-weight: 300;
                font-size: 16px;
                font-family: Onest;
                line-height: 1.3;

                & b {
                    font-weight: 500;
                }
            }
        }
    }
}
