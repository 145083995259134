.indexApp {
    position: relative;
    padding: 80rem 0 20rem;

    @include mob {
        padding: 106rem 0 20rem;
    }

    &__decor {
        position: absolute;
        animation: 8s infinite appDecor linear;

        @keyframes appDecor {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__decor._1 {
        top: 60rem;
        left: 63rem;
        width: 72rem;
        height: 72rem;

        @include bg("/media/app-decor-1.svg", contain, left top);

        @include mob {
            top: 4rem;
            left: 38rem;
            width: 54rem;
            height: 54rem;
        }
    }

    &__decor._2 {
        top: 140rem;
        left: 129rem;
        width: 40rem;
        height: 40rem;
        animation-delay: 0.2s;

        @include mob {
            top: 45rem;
            left: 105rem;
            width: 34rem;
            height: 34rem;
        }

        @include bg("/media/app-decor-2.svg", contain, left top);
    }

    &__head {
        margin-bottom: 50rem;

        &Title {
            margin-bottom: 16rem;
            color: #fff;
            font-weight: 600;
            font-size: 32rem;

            @include mob {
                margin-bottom: 14rem;
                font-size: 26rem;
                line-height: 1.3;
                text-align: center;
            }
        }

        &Description {
            color: #fff;
            font-weight: 500;
            font-size: 20rem;

            @include mob {
                font-size: 21rem;
                white-space: nowrap;
            }
        }
    }

    &__innerContent {
        position: relative;
        z-index: 1;
        width: 828rem;
        margin: 0 auto;

        @include mob {
            width: 100%;
        }

        &Box {
            position: relative;
            z-index: 1;
            padding: 50rem 92rem 82rem;
            overflow: hidden;
            background: #f22830;
            border-radius: 128rem;

            @include mob {
                padding: 76rem 17rem 106rem;
                border-radius: 80rem;
            }
        }
    }

    &__backLine {
        position: absolute;
        top: 50%;
        left: -10rem;
        z-index: -1;
        width: calc(100% + 20rem);
        height: 90rem;
        margin-top: -37rem;
        background: #ff49b8;
        transform: translate(0, -50%) rotate(-5.5deg);

        @include mob {
            height: 80rem;
            margin-top: 4rem;
        }

        &Item {
            position: absolute;
            top: 10rem;
            left: 0;
            height: calc(100% - 20rem);

            @include desk {
                animation: 6s infinite appBackLine linear;

                @keyframes appBackLine {
                    0% {
                        transform: translate(0, 0);
                    }

                    100% {
                        transform: translate(-868rem, 0);
                    }
                }

                &:nth-child(2) {
                    left: 868rem;
                }
            }

            @include mob {
                animation: 6s infinite appBackLineMob linear;

                @keyframes appBackLineMob {
                    0% {
                        transform: translate(0, 0);
                    }

                    100% {
                        transform: translate(-740rem, 0);
                    }
                }

                &:nth-child(2) {
                    left: 740rem;
                }
            }
        }
    }

    &__innerContentDecor {
        position: absolute;
        z-index: 5;

        &:not(._4) {
            opacity: 0;
        }
    }

    &__innerContentDecor._1 {
        top: 96rem;
        left: 61rem;
        width: 45rem;
        height: 45rem;

        @include bg("/media/app-decor-2.svg", contain, left top);

        animation: 2s infinite appInnerDecor1 ease-in-out;

        @include mob {
            top: 210rem;
            left: 18rem;
            width: 55rem;
            height: 55rem;
        }

        @keyframes appInnerDecor1 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(-40rem, -30rem);
                opacity: 0;
            }
        }
    }

    &__innerContentDecor._2 {
        top: 161rem;
        left: 23rem;
        width: 28rem;
        height: 27rem;

        @include bg("/media/app-decor-3.svg", contain, left top);

        @include mob {
            inset: auto 40rem 50rem auto;
        }

        @include desk {
            animation: 2s infinite appInnerDecor2 ease-in-out;
            animation-delay: 0.1s;

            @keyframes appInnerDecor2 {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(-30rem, -3rem);
                    opacity: 0;
                }
            }
        }

        @include mob {
            animation: 2s infinite appInnerDecorMob2 ease-in-out;
            animation-delay: 0.1s;

            @keyframes appInnerDecorMob2 {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(30rem, 20rem);
                    opacity: 0;
                }
            }
        }
    }

    &__innerContentDecor._3 {
        top: 175rem;
        left: 61rem;
        width: 33rem;
        height: 33rem;

        @include bg("/media/app-decor-4.svg", contain, left top);

        @include mob {
            inset: auto 96rem 22rem auto;
            width: 44rem;
            height: 44rem;
            animation: 2s infinite appInnerDecorMob3 ease-in-out;
            animation-delay: 0.2s;

            @keyframes appInnerDecorMob3 {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(-5rem, 30rem);
                    opacity: 0;
                }
            }
        }

        @include desk {
            animation: 2s infinite appInnerDecor3 ease-in-out;
            animation-delay: 0.2s;

            @keyframes appInnerDecor3 {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                80% {
                    opacity: 1;
                }

                100% {
                    transform: translate(-30rem, 20rem);
                    opacity: 0;
                }
            }
        }
    }

    &__innerContentDecor._4 {
        top: 180rem;
        right: 210rem;
        width: 68rem;
        height: 102rem;

        @include bg("/media/app-decor-5.svg", contain, left top);

        animation: 2s infinite appInnerDecor4 ease-in-out;

        @include mob {
            top: 270rem;
            right: auto;
            left: 40rem;
        }

        @keyframes appInnerDecor4 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(-3rem, 8rem);
            }

            100% {
                transform: translate(0, 0);
            }
        }
    }

    &__innerContentDecor._5 {
        top: 94rem;
        right: 87rem;
        width: 40rem;
        height: 27rem;

        @include bg("/media/app-decor-6.svg", contain, left top);

        animation: 2s infinite appInnerDecor5 ease-in-out;
        animation-delay: 0.2s;

        @include mob {
            top: 14rem;
            right: 77rem;
        }

        @keyframes appInnerDecor5 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(20rem, -30rem);
                opacity: 0;
            }
        }
    }

    &__innerContentDecor._6 {
        top: 112rem;
        right: 31rem;
        width: 33rem;
        height: 32rem;

        @include bg("/media/app-decor-7.svg", contain, left top);

        animation: 2s infinite appInnerDecor6 ease-in-out;

        @include mob {
            top: 40rem;
        }

        @keyframes appInnerDecor6 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(30rem, -20rem);
                opacity: 0;
            }
        }
    }

    &__content {
        position: relative;
        height: 316rem;

        @include mob {
            height: 410rem;
        }
    }

    &__screen {
        position: absolute;
        bottom: 10rem;
        left: 26rem;
        width: 170rem;

        @include mob {
            right: 30rem;
            bottom: 30rem;
            left: auto;
            width: 202rem;
        }

        &Image {
            display: block;
            width: 100%;
        }
    }

    &__block {
        position: relative;
        align-items: center;
        width: 368rem;
        margin: auto 0 0 auto;
        padding: 50rem 42rem 40rem 34rem;
        background: #10cfc9;
        border-radius: 36rem;

        @include mob {
            width: 100%;
            margin: auto 0 0;
            padding: 34rem 38rem 34rem 34rem;
            border-radius: 30rem;
        }

        &::before {
            position: absolute;
            top: 0;
            right: 30rem;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            width: 48rem;
            height: 48rem;
            padding-left: 3rem;
            color: #ff49b8;
            font-weight: 600;
            font-size: 20rem;
            background: #5d279e;
            border-radius: 50%;
            transform: translate(0, -50%);
            content: "18+";

            @include mob {
                right: auto;
                left: 38rem;
                width: 52rem;
                height: 52rem;
            }
        }

        &Code {
            position: absolute;
            bottom: 100%;
            left: -23rem;
            z-index: 2;
            width: 174rem;
            height: 174rem;
            margin-bottom: -30rem;
            padding: 24rem;
            background: #fff;
            border-radius: 36rem;
        }

        &Content {
            position: relative;
            width: calc(100% - 70rem);
            padding-right: 14rem;
            color: #fff;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;

            @include mob {
                font-size: 26rem;
            }

            &Link {
                color: #fff;
                border-bottom: 1px solid rgba(#fff, 0.72);
            }
        }

        &Logo {
            width: 70rem;
            height: 70rem;
            object-fit: contain;
        }
    }
}
