.faq {
    padding: 0 26rem;

    @include mob {
        padding: 0;
    }

    &__items {
        margin-bottom: 48rem;
    }

    &__item {
        & + & {
            margin-top: 4rem;
        }
    }

    &__form {
        width: 610rem;

        @include mob {
            width: 100%;
            padding: 0 16rem;
        }

        &Title {
            margin-bottom: 28rem;
            padding: 12rem 20rem;
            color: #5d279e;
            font-weight: 500;
            font-size: 24rem;
            line-height: 1.3;
            text-align: center;
            background: #ffd100;
            border-radius: 12rem;

            @include mob {
                font-weight: 600;
                font-size: 20rem;
            }
        }
    }
}
