.error {
    width: 100%;

    @include mob {
        max-width: 80%;
    }

    & .list__item {
        width: 100%;
    }

    &__item {
        width: 100%;

        &Inner {
            padding: 4rem 8rem;
            color: #fff;
            font-weight: 600;
            font-size: 16rem;
            line-height: 1.3;
            text-align: center;
            background: #e11f26;
            border-radius: 8rem;
        }
    }
}
