.profileRaffle {
    position: relative;
    z-index: 1;
    padding: 30rem 46rem;
    overflow: hidden;
    background: #fff;
    border-radius: 16rem;

    @include mob {
        padding: 30rem 20rem;
        border-radius: 20rem;
    }

    &__head {
        margin-bottom: 30rem;

        &Title {
            margin-bottom: 18rem;
            color: #5d279e;
            font-weight: 600;
            font-size: 20rem;
            line-height: 1.2;
            text-align: center;
            text-transform: uppercase;

            @include mob {
                margin-bottom: 5rem;
                font-size: 18rem;
            }
        }

        &Description {
            padding: 4rem 8rem;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.3;
            text-align: center;
            background: #ffd100;
            border-radius: 6rem;

            & span {
                position: relative;
                z-index: 1;
                display: inline-block;
                padding: 0 10rem;
                color: #fff;
                font-weight: 600;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    width: 22rem;
                    height: 22rem;
                    background: #5d279e;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    content: "";
                }
            }
        }
    }

    &__slider {
        position: relative;

        &Button {
            position: absolute;
            top: 90rem;
            z-index: 2;
            width: 36rem;
            height: 36rem;
            background: #f3f3f3;
            border-radius: 50%;
            transform: translate(0, -50%);

            &._prev {
                left: 0;
            }

            &._next {
                right: 0;
            }

            &Icon {
                width: 16rem;
                height: 16rem;

                @include icon(#e11f26);
            }

            &._prev &Icon {
                margin-left: -2rem;
            }

            &._next &Icon {
                margin-left: 2rem;
            }
        }

        &Items {
            display: inline-flex;
        }

        &Item {
            width: 380rem;
            margin-right: 50rem;

            @include mob {
                width: 290rem;
            }

            &Head {
                position: relative;
                width: 180rem;
                height: 180rem;
                margin-bottom: 20rem;
                background: #ffd100;
                border-radius: 50%;
            }

            &Image {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &Content {
                height: 40rem;
                color: $colorDark;
                font-weight: 600;
                font-size: 16rem;
                line-height: 1.3;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }

    &__button {
        margin-top: 20rem;
    }
}
