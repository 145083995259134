.indexWaitHeader {
    position: relative;
    min-height: 100vh;
    padding: 26rem 0 84rem;

    @include mob {
        padding: 17rem 0 70rem;
    }

    @include bg("/media/index/zagl-image.jpg", 100% auto, center top);

    background-color: #e11f26;

    @include mob {
        @include bg("/media/index/zagl-image-mob.jpg", 100% auto, center top);

        background-color: #e11f26;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        content: "";
        pointer-events: none;

        @include bg("/media/index/zagl-back.svg", 100% auto, center 406rem);

        @include mob {
            @include bg("/media/index/zagl-back-mob.svg", 100% auto, center 520rem);
        }
    }

    &__inner {
        z-index: 3;

        @include mob {
            padding-top: 60rem;
        }
    }

    &__slogan {
        width: 536rem;
        margin: 16rem 10rem 0 auto;

        @include mob {
            width: 310rem;
            margin: 0 0 0 auto;
        }
    }

    &__logo {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 146rem;

        @include mob {
            left: 50%;
            width: 124rem;
            transform: translate(-50%, 0);
        }
    }

    &__actions {
        position: relative;
        width: 100%;
        height: 200rem;

        @include mob {
            margin-top: 8rem;
        }
    }

    &__start {
        position: absolute;
        top: 8rem;
        right: 300rem;
        width: 268rem;
        height: 268rem;
        padding: 10rem 0 0 3rem;

        @include bg("/media/index/start-action-back.svg", contain, center);

        @include mob {
            right: 6rem;
            width: 174rem;
            height: 174rem;
            padding: 6rem 0 0 1rem;

            @include bg("/media/index/start-action-back-mob.svg", contain, center);
        }

        &Inner {
            color: #fff;
            font-weight: 700;
            font-size: 72rem;
            transition: $trans;

            @include mob {
                font-size: 47rem;
            }

            & span {
                margin-top: -4rem;
                color: #fff;
                font-weight: 600;
                font-size: 30rem;

                @include mob {
                    font-size: 20rem;
                }
            }
        }
    }

    &:not(._ready) &__startInner {
        opacity: 0;
    }

    &__date {
        position: absolute;
        top: 44rem;
        right: 124rem;
        z-index: 2;
        width: 168rem;
        height: 168rem;
        padding: 17rem 13rem 0 0;
        color: #fff;
        font-weight: 500;
        font-size: 22rem;
        line-height: 1.3;
        text-align: center;
        transform: rotate(15deg);

        @include mob {
            top: 18rem;
            right: 154rem;
            width: 130rem;
            height: 130rem;
            padding: 12rem 10rem 0 0;
            font-size: 15rem;
        }

        & b {
            color: #fff;
            font-weight: 600;
            line-height: 1.3;
        }

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: -1;
            width: 165%;
            height: 165%;
            transform: translate(-50%, -50%);
            content: "";

            @include bg("/media/index/start-date-back.svg", contain, center);

            @include mob {
                width: 140%;
                height: 140%;
            }
        }
    }

    &__foot {
        margin-top: 346rem;

        @include mob {
            margin-top: 376rem;
        }

        &Button {
            height: 68rem;
            margin-bottom: 42rem;
            padding: 10rem 28rem;
            color: #fff;
            font-weight: 600;
            font-size: 16rem;
            text-transform: uppercase;
            background: #ff49b8;
            border-radius: 12rem;
            transition: $trans;

            @include mob {
                height: 74rem;
                margin-bottom: 50rem;
                padding: 10rem 34rem;
                font-size: 18rem;
            }

            @include button;

            &:hover {
                color: #ff49b8;
                background: #fff;
            }
        }

        &Text {
            max-width: 682rem;
            color: #fcfcfc;
            font-size: 12rem;
            line-height: 1.7;
            text-align: center;

            @include mob {
                max-width: 340rem;
                font-size: 16rem;
            }
        }
    }
}
