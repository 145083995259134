.indexHeader {
    position: relative;
    z-index: 2;
    height: 800rem;
    padding: 96rem 0 84rem;

    @include bg("/media/index/zagl-image.jpg", 100% auto, center top);

    background-color: #e11f26;

    @include mob {
        height: 520rem;
        padding: 76rem 0 70rem;

        @include bg("/media/index-back-mob.jpg", 100% auto, center top);

        background-color: #e11f26;
    }

    &._5ka {
        height: 748rem;

        @include bg("/media/5ka-back.jpg", 100% auto, center top);

        background-color: #e11f26;

        &::before {
            position: absolute;
            bottom: 80rem;
            left: 444rem;
            z-index: -1;
            width: 350rem;
            height: 563rem;
            content: "";

            @include bg("/media/5ka/header-man-1.png", contain, left bottom);

            @include mob {
                bottom: 62rem;
                left: 90rem;
                width: 220rem;
                height: 290rem;
            }
        }

        &::after {
            position: absolute;
            bottom: 95rem;
            left: 146rem;
            z-index: 10;
            width: 404rem;
            height: 564rem;
            content: "";

            @include bg("/media/5ka/header-man-2.png", contain, left bottom);

            @include mob {
                bottom: 66rem;
                left: -67rem;
                width: 212rem;
                height: 312rem;
            }
        }

        @include mob {
            height: 560rem;
            padding: 76rem 0 70rem;

            @include bg("/media/5ka/image5ka-1-mob.jpg", 100% auto, center top);

            background-color: #e11f26;
        }
    }

    &__5ka {
        margin: 5rem 0 0 auto;
        padding: 6rem 20rem 5rem;
        color: #fff;
        font-weight: 700;
        font-size: 20rem;
        text-transform: uppercase;
        border: 3rem solid #fff;
        border-radius: 360rem;

        @include mob {
            margin-bottom: 5rem;
            padding: 5rem 8rem 4rem;
            font-size: 14rem;
            border-width: 2rem;
        }
    }

    &__slogan {
        width: 536rem;
        margin: 16rem 10rem 0 auto;

        @include mob {
            width: 310rem;
            margin: 0 0 0 auto;
        }
    }

    &._5ka &__slogan {
        @include mob {
            width: 290rem;
        }
    }

    &._5ka &__back {
        @include bg("/media/lenta-5ka-anima.svg", 100% auto, center calc(100% + 10rem));

        @include mob {
            @include bg("/media/5ka/image5ka-2-mob.svg", 100% auto, center bottom);
        }
    }

    &__back {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        pointer-events: none;

        @include bg("/media/lenta-main-anima.svg", 100% auto, center bottom);

        @include mob {
            @include bg("/media/lenta-main-anima-mob.svg", 100% auto, center bottom);
        }

        &Start {
            position: absolute;
            bottom: 81rem;
            left: -8rem;
            z-index: 3;
            width: 620rem;
            height: 68rem;
            overflow: hidden;
            transform: rotate(-5.5deg);

            @include mob {
                bottom: 49rem;
                width: 66rem;
                height: 37rem;
            }

            &::before,
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 680rem;
                height: 100%;
                content: "";

                @include mob {
                    top: -1rem;
                }

                @include bg("/media/header-left-line-elems.svg", auto calc(100% - 14rem), left 7rem);
            }

            @include desk {
                @keyframes backStartLine1 {
                    0% {
                        transform: translate(0, 0);
                    }

                    100% {
                        transform: translate(-100%, 0);
                    }
                }

                @keyframes backStartLine2 {
                    0% {
                        transform: translate(680rem, 0);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                &::before {
                    animation: 7s infinite backStartLine1 linear;
                }

                &::after {
                    transform: translate(680rem, 0);
                    animation: 7s infinite backStartLine2 linear;
                }
            }

            @include mob {
                @keyframes backStartLineMob1 {
                    0% {
                        transform: translate(0, 0);
                    }

                    100% {
                        transform: translate(-286rem, 0);
                    }
                }

                @keyframes backStartLineMob2 {
                    0% {
                        transform: translate(286rem, 0);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                &::before {
                    animation: 5s infinite backStartLineMob1 linear;
                }

                &::after {
                    transform: translate(286rem, 0);
                    animation: 5s infinite backStartLineMob2 linear;
                }
            }

            &Shadow {
                position: absolute;
                top: 4rem;
                right: 0;
                z-index: 50;
                width: 40rem;
                height: calc(100% - 14rem);
                background: linear-gradient(to left, #fff, rgba(#fff, 0));
                will-change: transform;

                @include mob {
                    width: 20rem;
                }
            }
        }

        &End {
            position: absolute;
            right: -7rem;
            bottom: 163rem;
            z-index: 2;
            width: 757rem;
            height: 63.5rem;
            overflow: hidden;

            // background: rgba(blue, 0.5);
            transform: rotate(-5.5deg);

            @include mob {
                bottom: 71rem;
                width: 278rem;
                height: 42rem;
            }

            &Inner {
                position: relative;
                z-index: 2;
                width: 330rem;
                height: 100%;
                margin-left: auto;
                overflow: hidden;

                // background: rgba(blue, 0.5);

                @include mob {
                    width: 60rem;
                }

                &::before,
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 620rem;
                    height: 100%;
                    content: "";

                    @include bg("/media/header-left-line-elems.svg", auto calc(100% - 14rem), left 7rem);
                }

                @include desk {
                    &::before {
                        animation: 6.8s infinite backEndLine1 linear;
                    }

                    &::after {
                        transform: translate(620rem, 0);
                        animation: 6.8s infinite backEndLine2 linear;
                    }

                    @keyframes backEndLine1 {
                        0% {
                            transform: translate(0, 0);
                        }

                        100% {
                            transform: translate(-100%, 0);
                        }
                    }

                    @keyframes backEndLine2 {
                        0% {
                            transform: translate(620rem, 0);
                        }

                        100% {
                            transform: translate(0, 0);
                        }
                    }
                }

                @include mob {
                    &::before {
                        animation: 5s infinite backEndLineMob1 linear;
                    }

                    &::after {
                        transform: translate(320rem, 0);
                        animation: 5s infinite backEndLineMob2 linear;
                    }

                    @keyframes backEndLineMob1 {
                        0% {
                            transform: translate(0, 0);
                        }

                        100% {
                            transform: translate(-320rem, 0);
                        }
                    }

                    @keyframes backEndLineMob2 {
                        0% {
                            transform: translate(320rem, 0);
                        }

                        100% {
                            transform: translate(0, 0);
                        }
                    }
                }
            }

            &Shadow {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 4;
                width: 40rem;
                height: 100%;
                background: linear-gradient(to right, #fff, rgba(#fff, 0));
            }
        }
    }

    &._5ka &__backStart {
        @include mob {
            bottom: 60rem;
        }
    }

    &._5ka &__backEnd {
        bottom: 188rem;
        width: 250rem;

        @include mob {
            bottom: 100rem;
            width: 38rem;
            height: 34rem;
        }
    }

    &._5ka &__backEndInner {
        @include mob {
            &::before,
            &::after {
                height: calc(100% + 6rem);
                margin-top: -3rem;
            }

            &::before {
                animation: 5s infinite backEndLineMob5ka1 linear;
            }

            &::after {
                transform: translate(270rem, 0);
                animation: 5s infinite backEndLineMob5ka2 linear;
            }

            @keyframes backEndLineMob5ka1 {
                0% {
                    transform: translate(0, 0);
                }

                100% {
                    transform: translate(-270rem, 0);
                }
            }

            @keyframes backEndLineMob5ka2 {
                0% {
                    transform: translate(270rem, 0);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }
    }
}
