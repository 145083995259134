.table {
    width: 100%;

    &__content {
        position: relative;
        width: 100%;
        padding: 0 16rem 16rem;
        background: #fff;
        border-radius: 12rem;

        @include mob {
            padding: 12rem;
            border-radius: 16rem;
        }

        &Inner {
            position: relative;
            z-index: 2;
            width: 100%;
            overflow: hidden;
            transition: $trans;
        }
    }

    &._balls &__content {
        max-height: 280rem;
        padding: 6rem;
        overflow: hidden;
        overflow-y: auto;
        background: #f4f4f4;
    }

    &__empty {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        &Title {
            color: #5d279e;
            font-weight: 600;
            font-size: 22rem;
            line-height: 1.4;
            text-align: center;
        }

        &Description {
            margin-top: 10rem;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.4;
            text-align: center;
        }
    }

    ._parentCalc &__rowWrapper,
    &__contentInner._static &__rowWrapper {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__rowWrapper:not(._show) &__row {
        opacity: 0;
    }

    &__row {
        display: flex;
        align-items: stretch;
        width: 100%;
        transition: $trans;

        &Wrapper {
            width: 100%;
        }

        @include mob {
            justify-content: space-between;
            padding: 12rem 15rem;
        }

        &._head {
            height: 52rem;
            padding: 0 16rem;
            background: #5d279e;
            border-radius: 12rem;

            @include mob {
                height: auto;
            }
        }

        &Wrapper._last & {
            // border-radius: 0 0 1.5rem 1.5rem;
        }

        &:not(._head) {
            background: #ffd100;
            border-radius: 12rem;

            @include mob {
                border-radius: 14rem;
            }

            &:not(._odd) {
                background: #fff;

                // background: #FFD100;
            }
        }

        &Inner {
            display: flex;
            align-items: center;
            width: 100%;

            & + & {
                margin-top: 6rem;
            }
        }
    }

    &._balls &__row._head {
        height: 40rem;
        padding: 0 6rem;
        background: #ff49b8;
    }

    &._balls &__row:not(._head, ._odd) {
        background: none;
    }

    ._parentCalc._balls &__row,
    &._balls &__row {
        @include mob {
            padding: 0;
        }
    }

    &._winners &__row {
        &:not(._head) {
            @include mob {
                background: #fff;

                &._odd {
                    background: #fac913;
                }
            }
        }
    }

    &._winners &__row._head {
        @include mob {
            display: none;
        }
    }

    &__row._head &__col {
        align-items: center;
        color: #fff;
        font-weight: 600;
        font-size: 16rem;
        text-transform: uppercase;

        @include mob {
            padding: 14rem 0;
        }
    }

    &._cheques &__row._head &__col {
        @include mob {
            padding: 0;
        }

        &Wrapper {
            @include mob {
                padding: 12rem 0;
            }
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 14rem 16rem;
        font-weight: 600;
        font-size: 16rem;
        line-height: 1.3;

        @include mob {
            justify-content: flex-start;
            padding: 0;
            font-size: 17rem;

            &:empty {
                display: none;
            }
        }

        &._head {
            @include mob {
                color: #5d279e;
            }
        }

        & span {
            font-weight: 600;
        }

        &Wrapper {
            display: flex;
            align-items: stretch;
            font-weight: 600;
            font-size: 16rem;
            line-height: 1.3;

            @include mob {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &Inner {
            font-weight: 600;
            line-height: 1.3;
            text-align: center;
            color: $colorDark;

            @include mob {
                text-align: left;
            }

            & span {
                font-weight: 600;

                &._support {
                    color: #5d279e;
                }
            }
        }
    }

    &__colWrapper._main &__col {
        width: 100%;
    }

    ._parentCalc._balls &__col,
    &._balls &__col {
        padding-top: 8rem;
        padding-bottom: 8rem;

        @include mob {
            padding: 10rem 6rem;
        }

        &Wrapper {
            @include mob {
                flex-direction: row;
            }
        }

        &Inner {
            text-align: center;
        }
    }

    ._parentCalc._cheques &__row._head &__col,
    &._cheques &__row._head &__col {
        @include mob {
            display: inline-block;
            width: auto;
            height: auto;
            margin-top: 0;
            line-height: 1.3;
        }

        &._result {
            @include mob {
                display: block;
                width: 100%;
            }
        }

        &Wrapper {
            @include mob {
                display: block;
            }
        }
    }

    ._parentCalc._winners &__row,
    &._winners &__row {
        @include mob {
            flex-direction: column;
        }
    }

    ._parentCalc._winners &__col,
    &._winners &__col {
        @include mob {
            &._head {
                width: 40% !important;
            }

            &:not(._head) {
                width: 60% !important;
            }
        }
    }

    ._parentCalc._winners &__col,
    &._winners &__col {
        align-items: center;

        @include mob {
            align-items: flex-start;
        }

        &Wrapper {
            &._main {
                width: 22%;

                @include mob {
                    width: 30%;
                }
            }

            &._other {
                width: 78%;

                @include mob {
                    width: 65%;
                }
            }
        }

        &._email {
            width: 27%;

            @include mob {
                width: 100%;
            }
        }

        &._phone {
            width: 30%;

            @include mob {
                width: 100%;
            }
        }

        &._prize {
            width: 43%;

            @include mob {
                width: 100%;
            }
        }
    }

    ._parentCalc._cheques &__col,
    &._cheques &__col {
        align-items: center;

        @include mob {
            align-items: flex-start;
        }

        &Wrapper {
            &._main {
                width: 20%;

                @include mob {
                    width: 40%;
                }
            }

            &._other {
                width: 80%;

                @include mob {
                    width: 60%;
                }
            }
        }

        &._fd {
            width: 20%;

            @include mob {
                width: 100%;
            }
        }

        &._status {
            width: 35%;

            @include mob {
                width: 100%;
            }
        }

        &._result {
            width: 45%;

            @include mob {
                width: 100%;
            }
        }
    }

    ._parentCalc._codes &__col,
    &._codes &__col {
        @include mob {
            justify-content: center;
        }

        align-items: center;

        &Wrapper {
            &._main {
                width: 50%;

                @include mob {
                    width: 40%;
                }
            }

            &._other {
                width: 50%;

                @include mob {
                    width: 60%;
                }
            }
        }

        &._date {
            width: 100%;

            @include mob {
                width: 100%;
            }
        }
    }

    ._parentCalc._balls &__col,
    &._balls &__col {
        @include mob {
            justify-content: center;
        }

        align-items: center;

        &Wrapper {
            &._main {
                width: 25%;

                @include mob {
                    width: 32%;
                }
            }

            &._other {
                width: 75%;

                @include mob {
                    width: 68%;
                }
            }
        }

        &._balls {
            width: 27%;
        }

        &._comment {
            width: 73%;
        }
    }
}
