.rules {
    position: relative;
    z-index: 1;
    padding: 40rem 0 0;

    @include mob {
        padding: 48rem 0 0;
    }

    &::before {
        position: absolute;
        top: -46rem;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        content: "";

        @include bg("/media/rules-back.svg", 100% auto, center top);

        @include mob {
            top: -7rem;
            right: 0;
            left: auto;
            width: calc(100% - 18rem);

            @include bg("/media/rules-back-mob.svg", 100% auto, right top);
        }
    }

    &__cards {
        display: flex;
        padding: 0 32rem 0 82rem;

        @include mob {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding: 0 20rem;
        }
    }

    &__card {
        position: relative;
        width: 16%;
        margin-right: 40rem;

        @include mob {
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-right: 0;

            &:nth-child(1) {
                margin-bottom: 66rem;
            }

            &:nth-child(2) {
                margin-bottom: 38rem;
            }

            &:nth-child(3) {
                margin-bottom: 66rem;
            }

            &:nth-child(4) {
                margin-bottom: 66rem;
            }
        }

        @include desk {
            &:nth-child(5) {
                width: 20%;
                margin-right: 0;
            }
        }

        &Next {
            position: absolute;
            top: 154rem;
            right: 0;
            z-index: 2;
            width: 24rem;
            height: 20rem;

            @include mob {
                display: none;
            }
        }

        &Head {
            position: relative;
            width: 148rem;
            height: 148rem;
            margin-bottom: 42rem;
            background: #ff49b8;
            border-radius: 50%;

            @include mob {
                width: 158rem;
                height: 158rem;
                margin-bottom: 0;
            }
        }

        &Image {
            position: absolute;
            bottom: 0;
            left: 50%;
            z-index: 2;
            width: 120%;
            height: 120%;
            object-fit: contain;
            object-position: center bottom;
            transform: translate(-50%, 0);
        }

        &Number {
            position: absolute;
            bottom: -28rem;
            left: -4rem;
            z-index: 2;
            width: 72rem;
            height: 72rem;
            color: #5d279e;
            font-weight: 600;
            font-size: 28rem;
            background: #fff;
            border-radius: 50%;

            @include mob {
                left: 0;
                width: 76rem;
                height: 76rem;
                font-size: 32rem;
            }
        }

        &Content {
            @include mob {
                width: calc(100% - 158rem);
                padding: 26rem 0 0 15rem;
            }
        }

        &:nth-child(5) &Content {
            @include mob {
                padding-top: 0;
            }
        }

        &Title {
            margin-bottom: 10rem;
            color: #5d279e;
            font-weight: 600;
            font-size: 20rem;
            line-height: 1.25;
            text-transform: uppercase;

            @include mob {
                margin-bottom: 7rem;
            }
        }

        @include desk {
            &:nth-child(2) &Title {
                width: 80%;
            }
        }

        &Description {
            color: $colorDark;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.3;

            @include mob {
                font-size: 18rem;
            }

            & a {
                color: #ff49b8;
                white-space: nowrap;
                border-bottom: 1px solid #ff49b8;
            }
        }
    }

    &__button {
        position: relative;
        z-index: 2;
        margin: -14rem 0 -20rem;

        @include mob {
            width: 196rem;
            margin: 30rem 0 0;
        }
    }
}
