.v2button {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20rem 24rem;
    color: #fff;
    font-weight: 600;
    font-size: 16rem;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
    border: 2rem solid transparent;
    border-radius: 12rem;

    @include button;

    @include mob {
        padding: 22rem 26rem;
        font-size: 17rem;
        border-radius: 10rem;
    }

    &._mediumSize {
        padding: 12rem 20rem;
        font-size: 13.5rem;
        border-radius: 10rem;

        @include mob {
            padding: 14rem 22rem;
            font-size: 16rem;
            border-radius: 11rem;
        }
    }

    &__loader {
        z-index: 10;
        background: inherit;
        border-radius: inherit;
    }

    &._purple {
        background: #5d279e;
    }

    &._purpleEmpty {
        color: #5d279e;
        border-color: #5d279e;
    }

    &._pink {
        background: #ff49b8;
    }

    &._pinkEmpty {
        color: #ff49b8;
        border-color: #ff49b8;
    }

    &._blue {
        background: #10cfc9;
    }
}
