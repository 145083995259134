.checkbox {
    position: relative;
    width: 100%;
    height: 100%;

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 0;
        height: 0;
        opacity: 0;
    }

    &__view {
        position: relative;
        padding-left: 36rem;

        &Inner {
            color: $colorDark;
            font-weight: 500;
            font-size: 16rem;
            line-height: 1.3;

            & a {
                display: inline-block;
                color: #ff49b8;
                line-height: 1;
                border-bottom: 1px solid #ff49b8;

                @include mob {
                    display: inline;
                }
            }
        }
    }

    &__box {
        position: absolute;
        top: -2rem;
        left: 0;
        width: 24rem;
        height: 24rem;
        background: #ff49b8;
        border-radius: 5rem;
        transition: $trans;

        &Icon {
            width: 14rem;
            height: 14rem;
            opacity: 0;
            transition: $trans;
        }
    }

    &__input:checked ~ &__view &__box {
        &Icon {
            opacity: 1;
        }
    }
}
