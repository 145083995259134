.index5kaPrizes {
    padding: 20rem 0 80rem;

    @include mob {
        padding: 46rem 0 80rem;
    }

    &__main {
        position: relative;
        z-index: 1;

        &Inner {
            position: relative;
            z-index: 10;
            width: 816rem;
            margin: 0 auto 120rem;

            @include mob {
                width: 88%;
                margin-bottom: 100rem;

                // overflow: hidden;
                // border-radius: 44rem;
            }

            &Box {
                position: relative;
                z-index: 1;
                padding: 28rem 28rem 24rem;
                overflow: hidden;
                background: #ff49b8;
                border-radius: 64rem;

                @include mob {
                    padding: 56rem 24rem 32rem;
                    border-radius: 44rem;
                }
            }
        }

        &Box {
            position: relative;
            z-index: 10;
        }

        &Title {
            margin-bottom: 28rem;
            color: #fff;
            font-weight: 600;
            font-size: 36rem;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;

            @include mob {
                margin-bottom: 20rem;
                font-size: 26rem;
            }
        }

        &Prize {
            position: relative;
            width: 320rem;
            height: 220rem;
            margin-bottom: 4rem;

            @include mob {
                width: 250rem;
                margin-bottom: -14rem;
                margin-left: -20rem;
            }

            &Image {
                object-fit: contain;
            }

            &Count {
                position: absolute;
                right: -50rem;
                bottom: 20rem;
                z-index: 2;
                width: 98rem;
                height: 98rem;
                padding-top: 4rem;
                color: #ff49b8;
                font-weight: 600;
                font-size: 48rem;
                background: #fff;
                border-radius: 50%;

                @include mob {
                    right: -16rem;
                    bottom: 42rem;
                    width: 78rem;
                    height: 78rem;
                    font-size: 40rem;
                }

                & span {
                    color: #ff49b8;
                    font-weight: 600;
                    font-size: 16rem;
                    text-transform: uppercase;

                    @include mob {
                        font-size: 13rem;
                    }
                }
            }
        }

        &Description {
            max-width: 480rem;
            color: #5d279e;
            font-weight: 600;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;
            text-transform: uppercase;

            @include mob {
                max-width: 80%;
                font-size: 16rem;
            }
        }
    }

    &__mainDecor {
        position: absolute;
        z-index: 2;
        pointer-events: none;
    }

    &__mainDecor._1 {
        top: -16rem;
        left: -56rem;
        display: flex;
        width: 103rem;
        height: 103rem;
        background: repeating-linear-gradient(#ff49b8, #ff49b8 2rem, #e60900 2rem, #e60900 6.5rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);

        @include mob {
            top: -92rem;
            left: 82rem;
            width: 76rem;
            height: 76rem;
            background: repeating-linear-gradient(#ff49b8, #ff49b8 2rem, #e60900 2rem, #e60900 5.5rem);
        }

        &::before {
            z-index: 2;
            display: block;
            width: 48rem;
            height: 48rem;
            margin: auto;
            background: #e60900;
            border-radius: 50%;
            content: "";

            @include mob {
                width: 34rem;
                height: 34rem;
            }
        }

        @include desk {
            animation: kaMainPrize1Desk 3s infinite linear;

            @keyframes kaMainPrize1Desk {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 103rem 103rem;
                }
            }
        }

        @include mob {
            animation: kaMainPrize1Mob 3s infinite linear;

            @keyframes kaMainPrize1Mob {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 76rem 76rem;
                }
            }
        }
    }

    &__mainDecor._2 {
        top: 126rem;
        left: -140rem;
        width: 53rem;
        height: 53rem;
        animation: kaMainPrize2 3s infinite linear;

        @include bg("/media/5ka/decor-1.svg", contain, center);

        @keyframes kaMainPrize2 {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(-360deg);
            }
        }

        @include mob {
            top: -46rem;
            left: -2rem;
            width: 26rem;
            height: 26rem;
        }
    }

    &__mainDecor._3 {
        top: 145rem;
        left: -118rem;
        z-index: 1;
        width: 122rem;
        height: 72rem;
        animation: kaMainPrize3 4s infinite ease-in-out;

        @include bg("/media/5ka/decor-2.svg", contain, center);

        @keyframes kaMainPrize3 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(30rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: -30rem;
            left: 0;
            width: 60rem;
            height: 30rem;
        }
    }

    &__mainDecor._4 {
        top: -72rem;
        right: -100rem;
        width: 46rem;
        height: 46rem;
        animation: kaMainPrize4 4s infinite linear;

        @include bg("/media/5ka/decor-3.svg", contain, center);

        @keyframes kaMainPrize4 {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        @include mob {
            top: -66rem;
            right: 2rem;
            width: 30rem;
            height: 30rem;
        }
    }

    &__mainDecor._5 {
        top: 68rem;
        right: -70rem;
        width: 66rem;
        height: 40rem;
        animation: kaMainPrize5 2s infinite ease-in-out;

        @include bg("/media/5ka/decor-4.svg", contain, center);

        @keyframes kaMainPrize5 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(-4rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: -64rem;
            right: 100rem;
            width: 42rem;
            height: 26rem;
        }
    }

    &__mainDecor._6 {
        top: 88rem;
        right: -64rem;
        z-index: 3;
        width: 34rem;
        height: 24rem;
        animation: kaMainPrize6 2s infinite ease-in-out;

        @include bg("/media/5ka/decor-5.svg", contain, center);

        @keyframes kaMainPrize6 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(4rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: -53rem;
            right: 96rem;
            width: 32rem;
            height: 18rem;
        }
    }

    &__mainDecor._7 {
        top: 188rem;
        right: -234rem;
        z-index: 3;
        width: 166rem;
        height: 58rem;
        animation: kaMainPrize7 2s infinite ease-in-out;

        @include bg("/media/5ka/decor-6.svg", contain, center);

        @keyframes kaMainPrize7 {
            0% {
                transform: scaleX(1);
            }

            50% {
                transform: scaleX(0.85);
            }

            100% {
                transform: scaleX(1);
            }
        }

        @include mob {
            top: -124rem;
            right: -70rem;
            width: 120rem;
            height: 44rem;
        }
    }

    &__mainDecor._8 {
        top: 243rem;
        right: 84rem;
        width: 132rem;
        height: 132rem;
        animation: kaMainPrize8 3s infinite ease-in-out;

        @include bg("/media/5ka/decor-7.svg", contain, center);

        @keyframes kaMainPrize8 {
            0% {
                transform: scale(0.8);
            }

            50% {
                transform: scale(1.1);
            }

            100% {
                transform: scale(0.8);
            }
        }

        @include mob {
            top: -10rem;
            right: -40rem;
            width: 100rem;
            height: 100rem;
        }
    }

    &__mainInnerDecor {
        position: absolute;
        z-index: 2;
    }

    &__mainInnerDecor._1 {
        top: -284rem;
        left: -210rem;
        width: 1172rem;
        height: 986rem;
        animation: kaMainInnerDecor1 3s infinite ease-in-out;

        @include bg("/media/5ka/mainInnerDecor-1.svg", contain, center);

        @keyframes kaMainInnerDecor1 {
            0% {
                transform: rotate(0deg) scale(1);
            }

            50% {
                transform: rotate(-10deg) scale(1.2);
            }

            100% {
                transform: rotate(0deg) scale(1);
            }
        }

        @include mob {
            top: -230rem;
            left: -250rem;
            width: 800rem;
            height: 800rem;
        }
    }

    &__mainInnerDecor._2 {
        bottom: 141rem;
        left: 34rem;
        width: 23rem;
        height: 18rem;
        animation: kaMainInnerDecor2 2s infinite ease-in-out;

        @include bg("/media/5ka/mainInnerDecor-2.svg", contain, center);

        @keyframes kaMainInnerDecor2 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            70% {
                opacity: 1;
            }

            100% {
                transform: translate(-60rem, 40rem);
                opacity: 0;
            }
        }

        @include mob {
            bottom: 110rem;
            left: 28rem;
            width: 18rem;
            height: 16rem;
        }
    }

    &__mainInnerDecor._3 {
        top: 116rem;
        left: 56rem;
        width: 39rem;
        height: 41rem;
        animation: kaMainInnerDecor3 2s infinite ease-in-out;

        @include bg("/media/5ka/mainInnerDecor-3.svg", contain, center);

        @keyframes kaMainInnerDecor3 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            70% {
                opacity: 1;
            }

            100% {
                transform: translate(-60rem, -40rem);
                opacity: 0;
            }
        }

        @include mob {
            top: 10rem;
            left: 60rem;
            width: 34rem;
            height: 36rem;
        }
    }

    &__mainInnerDecor._4 {
        bottom: 142rem;
        left: 138rem;
        width: 69rem;
        height: 89rem;
        animation: kaMainInnerDecor4 2s infinite ease-in-out;

        @include bg("/media/5ka/mainInnerDecor-4.svg", contain, center);

        @keyframes kaMainInnerDecor4 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            70% {
                opacity: 1;
            }

            100% {
                transform: translate(-30rem, -10rem) rotate(-10deg);
                opacity: 0;
            }
        }

        @include mob {
            top: 110rem;
            bottom: auto;
            left: 14rem;
            width: 36rem;
            height: 36rem;
        }
    }

    &__mainInnerDecor._5 {
        top: 78rem;
        left: 231rem;
        width: 27rem;
        height: 34rem;
        animation: kaMainInnerDecor5 2s infinite ease-in-out;

        @include bg("/media/5ka/mainInnerDecor-5.svg", contain, center);

        @keyframes kaMainInnerDecor5 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            70% {
                opacity: 1;
            }

            100% {
                transform: translate(-40rem, -30rem) rotate(15deg);
                opacity: 0;
            }
        }

        @include mob {
            top: 4rem;
            left: 170rem;
            width: 22rem;
            height: 28rem;
        }
    }

    &__mainInnerDecor._6 {
        top: 90rem;
        left: 336rem;
        width: 98rem;
        height: 98rem;
        animation: kaMainInnerDecor6 4s infinite linear;

        @include bg("/media/5ka/mainInnerDecor-6.svg", contain, center);

        @keyframes kaMainInnerDecor6 {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        @include mob {
            top: 116rem;
            left: 110rem;
            width: 80rem;
            height: 80rem;
        }
    }

    &__mainInnerDecor._7 {
        top: 125rem;
        right: 228rem;
        width: 34rem;
        height: 34rem;
        animation: kaMainInnerDecor7 2s infinite ease-in-out;

        @include bg("/media/5ka/mainInnerDecor-7.svg", contain, center);

        @keyframes kaMainInnerDecor7 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            70% {
                opacity: 1;
            }

            100% {
                transform: translate(20rem, -50rem) rotate(360deg);
                opacity: 0;
            }
        }

        @include mob {
            top: 110rem;
            right: 5rem;
        }
    }

    &__mainInnerDecor._8 {
        top: 93rem;
        right: 115rem;
        width: 80rem;
        height: 98rem;
        animation: kaMainInnerDecor8 2s infinite ease-in-out;

        @include bg("/media/5ka/mainInnerDecor-8.svg", contain, center);

        @keyframes kaMainInnerDecor8 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(12rem, -16rem);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: auto;
            right: -5rem;
            bottom: 90rem;
            width: 40rem;
            height: 40rem;
        }
    }

    &__mainInnerDecor._9 {
        right: 54rem;
        bottom: 54rem;
        width: 40rem;
        height: 37rem;

        @include bg("/media/5ka/mainInnerDecor-9.svg", contain, center);

        @include desk {
            animation: kaMainInnerDecor9Desk 2s infinite ease-in-out;

            @keyframes kaMainInnerDecor9Desk {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                70% {
                    opacity: 1;
                }

                100% {
                    transform: translate(30rem, 30rem) rotate(-10deg);
                    opacity: 0;
                }
            }
        }

        @include mob {
            animation: kaMainInnerDecor9Mob 2s infinite ease-in-out;

            @keyframes kaMainInnerDecor9Mob {
                0% {
                    transform: translate(0, 0);
                    opacity: 0;
                }

                20% {
                    opacity: 1;
                }

                70% {
                    opacity: 1;
                }

                100% {
                    transform: translate(-30rem, 30rem) rotate(-10deg);
                    opacity: 0;
                }
            }
        }

        @include mob {
            right: auto;
            bottom: 20rem;
            left: 20rem;
            width: 20rem;
            height: 17rem;
        }
    }

    &__mainInnerDecor._10 {
        right: 148rem;
        bottom: 76rem;
        width: 30rem;
        height: 27rem;
        animation: kaMainInnerDecor10 2s infinite ease-in-out;

        @include bg("/media/5ka/mainInnerDecor-10.svg", contain, center);

        @keyframes kaMainInnerDecor10 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            70% {
                opacity: 1;
            }

            100% {
                transform: translate(-30rem, 50rem) rotate(-5deg);
                opacity: 0;
            }
        }

        @include mob {
            right: auto;
            bottom: 5rem;
            left: 100rem;
            width: 23rem;
            height: 20rem;
        }
    }

    &__mainInnerDecor._11 {
        top: 170rem;
        right: 29rem;
        width: 32rem;
        height: 32rem;
        border: 5rem solid #fff;
        border-radius: 50%;
        animation: kaMainInnerDecor11 1s infinite ease-in-out;

        @keyframes kaMainInnerDecor11 {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.15);
            }

            100% {
                transform: scale(1);
            }
        }

        @include mob {
            top: auto;
            right: 56rem;
            bottom: -8rem;
            width: 26rem;
            height: 26rem;
        }
    }

    &__mainInnerDecor._12 {
        top: 213rem;
        right: 21rem;
        width: 16rem;
        height: 16rem;
        border: 3rem solid #fff;
        border-radius: 50%;
        animation: kaMainInnerDecor12 1s infinite ease-in-out;

        @keyframes kaMainInnerDecor12 {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(0.85);
            }

            100% {
                transform: scale(1);
            }
        }

        @include mob {
            top: auto;
            right: 36rem;
            bottom: 13rem;
            width: 14rem;
            height: 14rem;
            border-width: 3rem;
        }
    }

    &__rules {
        position: relative;
        margin-bottom: 78rem;

        @include mob {
            width: 330rem;
            margin: 0 auto 70rem;
        }

        &Inner {
            display: flex;
            align-items: stretch;
            justify-content: space-between;

            @include mob {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }

    &__rulesDecor {
        position: absolute;
        z-index: 1;
    }

    &__rulesDecor._1 {
        top: -50rem;
        left: 353rem;
        width: 195rem;
        height: 115rem;
        animation: kaRulesPrize1 3s infinite ease-in-out;

        @include bg("/media/5ka/decor-2.svg", contain, center);

        @keyframes kaRulesPrize1 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(-30rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            display: none;
        }
    }

    &__rulesDecor._2 {
        top: -50rem;
        right: -300rem;
        display: flex;
        width: 228rem;
        height: 228rem;
        background: repeating-linear-gradient(#e11f26, #e11f26 4.5rem, #ea232b 4.5rem, #ea232b 14.2rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);

        &::before {
            z-index: 2;
            display: block;
            width: 104rem;
            height: 104rem;
            margin: auto;
            background: #e11f26;
            border-radius: 50%;
            content: "";

            @include mob {
                width: 50rem;
                height: 50rem;
            }
        }

        @include desk {
            animation: kaRulesPrize2Desk 4s infinite linear;

            @keyframes kaRulesPrize2Desk {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 228rem 228rem;
                }
            }
        }

        @include mob {
            right: auto;
            left: -50rem;
            width: 120rem;
            height: 120rem;
            background: repeating-linear-gradient(#e11f26, #e11f26 3rem, #ea232b 3rem, #ea232b 7rem);
            animation: kaRulesPrize2Mob 4s infinite linear;

            @keyframes kaRulesPrize2Mob {
                0% {
                    background-position: 0 0;
                }

                100% {
                    background-position: 120rem 120rem;
                }
            }
        }
    }

    &__rulesDecor._3 {
        bottom: -170rem;
        left: -240rem;
        display: flex;
        width: 140rem;
        height: 140rem;
        background: repeating-linear-gradient(#ff1c24, #ff1c24 2rem, #e11f26 2rem, #e11f26 10rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);
        animation: kaRulesPrize3 4s infinite linear;

        &::before {
            z-index: 2;
            display: block;
            width: 64rem;
            height: 64rem;
            margin: auto;
            background: #ff49b8;
            border-radius: 50%;
            content: "";

            @include mob {
                width: 50rem;
                height: 50rem;
            }
        }

        @keyframes kaRulesPrize3 {
            0% {
                background-position: 0 0;
            }

            100% {
                background-position: 140rem 140rem;
            }
        }

        @include mob {
            right: -100rem;
            bottom: 720rem;
            left: auto;
            width: 110rem;
            height: 110rem;
        }
    }

    &__rule {
        position: relative;
        z-index: 2;
        width: 320rem;
        padding: 30rem 24rem 30rem 30rem;
        background: #fff;
        border-radius: 48rem;

        @include mob {
            width: 100%;
            padding: 30rem 24rem;

            & + & {
                margin-top: 96rem;
            }

            &:first-child {
                padding-bottom: 130rem;
            }
        }

        &Icon {
            position: absolute;
            top: 50%;
            left: 100%;
            z-index: 2;
            width: 33rem;
            height: 33rem;
            margin-left: 31rem;
            transform: translate(0, -50%);

            @include mob {
                top: 100%;
                left: 26rem;
                margin-top: 34rem;
                margin-left: 0;
                transform: translate(0, 0) rotate(90deg);
            }
        }

        &Head {
            position: relative;
            margin-bottom: 20rem;

            @include mob {
                margin-bottom: 26rem;
            }
        }

        &Number {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 72rem;
            height: 72rem;
            color: #fff;
            font-weight: 600;
            font-size: 28rem;
            background: #e11f26;
            border-radius: 50%;
        }

        &Prize {
            position: relative;
            z-index: 1;
            width: 188rem;
            height: 188rem;
            margin: -66rem 0 0 42rem;
            background: #ff49b8;
            border-radius: 50%;

            @include mob {
                margin-left: 50rem;
            }

            &Image {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: 115%;
                height: 115%;
                transform: translate(-50%, 0);
            }
        }

        &PrizeDecor {
            position: absolute;
        }

        &._1 &PrizeDecor._1 {
            top: 62rem;
            left: -16rem;
            width: 13rem;
            height: 16rem;
            animation: kaPrize1Decor1 2s infinite ease-in-out;

            @include bg("/media/5ka/rulesPrizeDecor1-1.svg", contain, center);

            @keyframes kaPrize1Decor1 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(0, -8rem) rotate(10deg);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &._1 &PrizeDecor._2 {
            top: 85rem;
            left: 5rem;
            width: 27rem;
            height: 28rem;
            animation: kaPrize1Decor2 2s infinite ease-in-out;

            @include bg("/media/5ka/rulesPrizeDecor1-2.svg", contain, center);

            @keyframes kaPrize1Decor2 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: rotate(-20deg);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &._1 &PrizeDecor._3 {
            top: 53rem;
            right: 3rem;
            width: 31rem;
            height: 41rem;
            animation: kaPrize1Decor3 1.5s infinite ease-in-out;

            @include bg("/media/5ka/rulesPrizeDecor1-3.svg", contain, center);

            @keyframes kaPrize1Decor3 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(-2rem, 6rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &._1 &PrizeDecor._4 {
            right: 7rem;
            bottom: 17rem;
            width: 70rem;
            height: 70rem;
            background: #10cfc9;
            border-radius: 50%;
        }

        &._1 &PrizeDecor._5 {
            right: 22rem;
            bottom: 15rem;
            z-index: 3;
            width: 64rem;
            height: 53rem;
            animation: kaPrize1Decor5 1.5s infinite ease-in-out;

            @include bg("/media/5ka/rulesPrizeDecor1-4.svg", contain, center);

            @keyframes kaPrize1Decor5 {
                0% {
                    transform: scaleY(1);
                }

                50% {
                    transform: scaleY(1.1);
                }

                100% {
                    transform: scaleY(1);
                }
            }
        }

        &._1 &PrizeDecor._6 {
            top: -18rem;
            left: 21rem;
            z-index: 4;
            width: 67rem;
            height: 155rem;
            animation: kaPrize1Decor6 2s infinite ease-in-out;

            @include bg("/media/5ka/5ka-step-1-1.png", contain, center);

            @keyframes kaPrize1Decor6 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(-1rem, -8rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &._1 &PrizeDecor._7 {
            top: 11rem;
            right: 49rem;
            z-index: 2;
            width: 66rem;
            height: 140rem;
            animation: kaPrize1Decor7 2s infinite ease-in-out;
            animation-delay: 0.5s;

            @include bg("/media/5ka/5ka-step-1-2.png", contain, center);

            @keyframes kaPrize1Decor7 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(1rem, -8rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &._1 &PrizeDecor._8 {
            top: 35rem;
            right: 33rem;
            width: 14rem;
            height: 14rem;
            border: 4rem solid #e11f26;
            border-radius: 50%;
            animation: kaPrize1Decor8 1s infinite ease-in-out;

            @keyframes kaPrize1Decor8 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &._1 &PrizeDecor._9 {
            top: 19rem;
            right: 32rem;
            width: 8rem;
            height: 8rem;
            border: 2rem solid #fff;
            border-radius: 50%;
            animation: kaPrize1Decor9 1s infinite ease-in-out;

            @keyframes kaPrize1Decor9 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(0.8);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &._2 &PrizeDecor._1 {
            top: 42rem;
            left: 24rem;
            z-index: 2;
            width: 66rem;
            height: 73rem;
            animation: kaPrize2Decor1 1.3s infinite ease-in-out;

            @include bg("/media/5ka/rulesPrizeDecor2-1.svg", contain, center);

            @keyframes kaPrize2Decor1 {
                0% {
                    transform: scale(1);
                }

                20% {
                    transform: scale(1);
                }

                35% {
                    transform: scale(1.05);
                }

                50% {
                    transform: scale(1);
                }

                60% {
                    transform: scale(1.05);
                }

                70% {
                    transform: scale(1);
                }

                80% {
                    transform: scale(1.05);
                }

                90% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &._2 &PrizeDecor._2 {
            top: 48rem;
            left: 55rem;
            z-index: 3;
            width: 111rem;
            height: 102rem;

            @include bg("/media/5ka/rulesPrizeDecor2-2.svg", contain, center);

            animation: kaPrize2Decor2 1.3s infinite ease-in-out;
            animation-delay: 0.1s;

            @keyframes kaPrize2Decor2 {
                0% {
                    transform: scale(1);
                }

                20% {
                    transform: scale(1);
                }

                35% {
                    transform: scale(1.05);
                }

                50% {
                    transform: scale(1);
                }

                60% {
                    transform: scale(1.05);
                }

                70% {
                    transform: scale(1);
                }

                80% {
                    transform: scale(1.05);
                }

                90% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &._2 &PrizeDecor._3 {
            top: -6rem;
            left: 67rem;
            width: 35rem;
            height: 41rem;

            @include bg("/media/5ka/rulesPrizeDecor2-3.svg", contain, center);

            animation: kaPrize2Decor3 1.7s infinite ease-in-out;

            @keyframes kaPrize2Decor3 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(10rem, 10rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &._2 &PrizeDecor._4 {
            top: 24rem;
            right: 30rem;
            width: 31rem;
            height: 28rem;

            @include bg("/media/5ka/rulesPrizeDecor2-4.svg", contain, center);

            animation: kaPrize2Decor4 1.7s infinite ease-in-out;

            @keyframes kaPrize2Decor4 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(20deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &._2 &PrizeDecor._5 {
            top: 57rem;
            right: 11rem;
            width: 17rem;
            height: 17rem;

            @include bg("/media/5ka/rulesPrizeDecor2-5.svg", contain, center);

            animation: kaPrize2Decor5 1.7s infinite ease-in-out;

            @keyframes kaPrize2Decor5 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(-20deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &._2 &PrizeDecor._6 {
            right: 70rem;
            bottom: 20rem;
            width: 15rem;
            height: 15rem;
            border: 2rem solid #fff;
            border-radius: 50%;
            animation: kaPrize2Decor6 1s infinite ease-in-out;

            @keyframes kaPrize2Decor6 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.15);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &._3 &PrizeDecor._1 {
            top: 72rem;
            left: 8rem;
            width: 14rem;
            height: 18rem;

            @include bg("/media/5ka/rulesPrizeDecor3-1.svg", contain, center);

            animation: kaPrize3Decor1 1.7s infinite ease-in-out;

            @keyframes kaPrize3Decor1 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(12deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &._3 &PrizeDecor._2 {
            top: 34rem;
            left: 37rem;
            width: 12rem;
            height: 12rem;

            @include bg("/media/5ka/rulesPrizeDecor3-2.svg", contain, center);

            animation: kaPrize3Decor2 1.7s infinite ease-in-out;

            @keyframes kaPrize3Decor2 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(-12deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &._3 &PrizeDecor._3 {
            top: 15rem;
            right: 52rem;
            width: 27rem;
            height: 27rem;
            transform-origin: right bottom;

            @include bg("/media/5ka/rulesPrizeDecor3-3.svg", contain, center);

            animation: kaPrize3Decor3 1.7s infinite ease-in-out;

            @keyframes kaPrize3Decor3 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(-12deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &._3 &PrizeDecor._4 {
            top: 41rem;
            right: 74rem;
            z-index: 5;
            width: 18rem;
            height: 24rem;

            @include bg("/media/5ka/rulesPrizeDecor3-4.svg", contain, center);

            animation: kaPrize3Decor4 1.3s infinite ease-in-out;

            @keyframes kaPrize3Decor4 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(-3rem, 5rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &._3 &PrizeDecor._5 {
            right: 9rem;
            bottom: 80rem;
            width: 17rem;
            height: 17rem;

            @include bg("/media/5ka/rulesPrizeDecor3-5.svg", contain, center);

            animation: kaPrize3Decor5 1.3s infinite ease-in-out;

            @keyframes kaPrize3Decor5 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(15deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }
        }

        &._3 &PrizeDecor._6 {
            right: 64rem;
            bottom: 16rem;
            width: 18rem;
            height: 19rem;

            @include bg("/media/5ka/rulesPrizeDecor3-6.svg", contain, center);

            animation: kaPrize3Decor6 1.3s infinite ease-in-out;

            @keyframes kaPrize3Decor6 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(0, 4rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }
        }

        &._3 &PrizeDecor._7 {
            top: 68rem;
            right: 15rem;
            width: 12rem;
            height: 12rem;
            border: 2rem solid #e11f26;
            border-radius: 50%;
            animation: kaPrize3Decor7 1.3s infinite ease-in-out;

            @keyframes kaPrize3Decor7 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &._3 &PrizeDecor._8 {
            bottom: 52rem;
            left: 31rem;
            z-index: 1;
            width: 54rem;
            height: 75rem;

            @include bg("/media/5ka/rulesPrizeDecor3-7.svg", contain, center);

            animation: kaPrize3Decor8 1.3s infinite ease-in-out;

            @keyframes kaPrize3Decor8 {
                0% {
                    transform: scale(1);
                }

                20% {
                    transform: scale(1);
                }

                35% {
                    transform: scale(1.03);
                }

                50% {
                    transform: scale(1);
                }

                60% {
                    transform: scale(1.05);
                }

                70% {
                    transform: scale(1);
                }

                80% {
                    transform: scale(1.05);
                }

                90% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &._3 &PrizeDecor._9 {
            bottom: 42rem;
            left: 60rem;
            z-index: 3;
            width: 61rem;
            height: 72rem;

            @include bg("/media/5ka/rulesPrizeDecor3-8.svg", contain, center);

            animation: kaPrize3Decor9 0.5s infinite ease-in-out;
            animation-delay: 0.1s;

            @keyframes kaPrize3Decor9 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.04);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &._3 &PrizeDecor._10 {
            right: 35rem;
            bottom: 48rem;
            z-index: 2;
            width: 45rem;
            height: 80rem;

            @include bg("/media/5ka/rulesPrizeDecor3-9.svg", contain, center);

            animation: kaPrize3Decor10 1.3s infinite ease-in-out;
            animation-delay: 0.2s;

            @keyframes kaPrize3Decor10 {
                0% {
                    transform: scale(1);
                }

                20% {
                    transform: scale(1);
                }

                35% {
                    transform: scale(1.03);
                }

                50% {
                    transform: scale(1);
                }

                60% {
                    transform: scale(1.05);
                }

                70% {
                    transform: scale(1);
                }

                80% {
                    transform: scale(1.05);
                }

                90% {
                    transform: scale(1);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &._3 &PrizeDecor._11 {
            top: 36rem;
            left: 66rem;
            width: 45rem;
            height: 48rem;

            @include bg("/media/5ka/rulesPrizeDecor3-10.svg", contain, center);

            animation: kaPrize3Decor11 0.5s infinite ease-in-out;

            @keyframes kaPrize3Decor11 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.05);
                }

                100% {
                    transform: scale(1);
                }
            }
        }

        &Title {
            margin-bottom: 8rem;
            color: #5d279e;
            font-weight: 600;
            font-size: 24rem;
            line-height: 1.3;
            text-transform: uppercase;

            @include mob {
                font-size: 26rem;
            }
        }

        &Description {
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
        }
    }

    &__info {
        position: relative;
        z-index: 1;
        width: 816rem;
        height: 104rem;
        margin: 0 auto 64rem;

        @include mob {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
        }

        &Top {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: calc(100% + 80rem);
            overflow: hidden;
            border-radius: 36rem;
            pointer-events: none;

            &::before,
            &::after {
                position: absolute;
                content: "";
            }

            &::before {
                bottom: 5rem;
                left: 66rem;
                width: 77rem;
                height: 132rem;

                @include bg("/media/5ka/info/5-ka-x2-1.png", contain, center);

                animation: kaInfoTop1 2s infinite ease-in-out;

                @keyframes kaInfoTop1 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(-2rem, -10rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                @include mob {
                    bottom: 20rem;
                    left: 20rem;
                    z-index: 1;
                    width: 54rem;
                    height: 100rem;
                }
            }

            &::after {
                bottom: -20rem;
                left: 121rem;
                width: 72rem;
                height: 122rem;

                @include bg("/media/5ka/info/5-ka-x2-2.png", contain, center);

                animation: kaInfoTop2 2s infinite ease-in-out;
                animation-delay: 0.2s;

                @keyframes kaInfoTop2 {
                    0% {
                        transform: translate(0, 0);
                    }

                    50% {
                        transform: translate(1rem, -10rem);
                    }

                    100% {
                        transform: translate(0, 0);
                    }
                }

                @include mob {
                    left: 37rem;
                    z-index: 0;
                    width: 48rem;
                    height: 90rem;
                }
            }
        }

        &Inner {
            position: relative;
            z-index: 1;
            overflow: hidden;
            background: #ff49b8;
            border-radius: 36rem;
        }

        &Title {
            max-width: 424rem;
            margin-left: 24rem;
            color: #fff;
            font-weight: 500;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;

            @include mob {
                max-width: 60%;
                margin-left: 0;
                font-size: 16rem;
            }

            & b {
                color: #fff;
                font-weight: 700;
            }
        }

        &Support {
            position: absolute;
            right: 78rem;
            bottom: 24rem;
            z-index: 2;
            width: 50rem;
            height: 50rem;

            @include mob {
                right: 27rem;
                bottom: 27rem;
                width: 36rem;
                height: 36rem;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: 153rem;
                height: 148rem;
                transform: translate(-50%, -50%);
                animation: kaInfoSupport 1s infinite ease-in-out;
                content: "";

                @include bg("/media/5ka/info/decor-6.svg", contain, center);

                @keyframes kaInfoSupport {
                    0% {
                        transform: translate(-50%, -50%) scale(1);
                    }

                    50% {
                        transform: translate(-50%, -50%) rotate(-4deg) scale(1.05);
                    }

                    100% {
                        transform: translate(-50%, -50%) scale(1);
                    }
                }

                @include mob {
                    width: 100rem;
                    height: 100rem;
                }
            }

            &Image {
                display: block;
                width: 100%;
                margin-bottom: 3rem;
                object-fit: contain;
            }

            &Text {
                color: #fff;
                font-weight: 600;
                font-size: 16rem;
                text-align: center;

                @include mob {
                    font-size: 14rem;
                }
            }
        }

        &Decor {
            position: absolute;
            z-index: 3;
        }

        &Decor._1 {
            bottom: 23rem;
            left: 2rem;
            width: 23rem;
            height: 18rem;

            @include bg("/media/5ka/info/decor-1.svg", contain, center);

            animation: kaInfoDecor1 1.5s infinite ease-in-out;

            @keyframes kaInfoDecor1 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(-15deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }

            @include mob {
                bottom: 28rem;
                width: 17rem;
            }
        }

        &Decor._2 {
            top: -7rem;
            left: 23rem;
            width: 39rem;
            height: 42rem;

            @include bg("/media/5ka/info/decor-2.svg", contain, center);

            animation: kaInfoDecor2 1.5s infinite ease-in-out;

            @keyframes kaInfoDecor2 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(-5rem, 7rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }

            @include mob {
                top: 20rem;
                left: 2rem;
                width: 23rem;
                height: 30rem;
            }
        }

        &Decor._3 {
            bottom: 23rem;
            left: 42rem;
            width: 22rem;
            height: 28rem;

            @include bg("/media/5ka/info/decor-3.svg", contain, center);

            animation: kaInfoDecor3 1.3s infinite ease-in-out;

            @keyframes kaInfoDecor3 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(-1rem, -4rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }

            @include mob {
                bottom: 14rem;
                left: 20rem;
                width: 18rem;
                height: 24rem;
            }
        }

        &Decor._4 {
            top: 50%;
            left: 50%;
            z-index: -1;
            width: 1172rem;
            height: 986rem;
            transform: translate(-50%, -50%);

            @include bg("/media/5ka/info/decor-4.svg", contain, center);

            animation: kaInfoDecor4 2.5s infinite ease-in-out;

            @keyframes kaInfoDecor4 {
                0% {
                    transform: translate(-50%, -50%);
                }

                50% {
                    transform: translate(-50%, -50%) scale(0.9) rotate(-1deg);
                }

                100% {
                    transform: translate(-50%, -50%);
                }
            }

            @include mob {
                width: 300rem;
                height: 300rem;
            }
        }

        &Decor._5 {
            top: -8rem;
            right: 110rem;
            width: 36rem;
            height: 44rem;

            @include bg("/media/5ka/info/decor-5.svg", contain, center);

            animation: kaInfoDecor5 1.2s infinite ease-in-out;

            @keyframes kaInfoDecor5 {
                0% {
                    transform: translate(0, 0);
                }

                50% {
                    transform: translate(5rem, 5rem);
                }

                100% {
                    transform: translate(0, 0);
                }
            }

            @include mob {
                top: 13rem;
                right: 52rem;
                width: 28rem;
                height: 28rem;
            }
        }

        &Decor._6 {
            top: 18rem;
            right: 10rem;
            width: 25rem;
            height: 23rem;

            @include bg("/media/5ka/info/decor-7.svg", contain, center);

            animation: kaInfoDecor6 1.2s infinite ease-in-out;

            @keyframes kaInfoDecor6 {
                0% {
                    transform: rotate(0deg);
                }

                50% {
                    transform: rotate(12deg);
                }

                100% {
                    transform: rotate(0deg);
                }
            }

            @include mob {
                top: 3rem;
                right: 2rem;
            }
        }

        &Decor._7 {
            top: 43rem;
            right: 34rem;
            width: 14rem;
            height: 14rem;
            border: 3rem solid #fff;
            border-radius: 50%;
            animation: kaInfoDecor7 1.2s infinite ease-in-out;

            @keyframes kaInfoDecor7 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(0.8);
                }

                100% {
                    transform: scale(1);
                }
            }

            @include mob {
                top: 27rem;
                right: 20rem;
                width: 9rem;
                height: 9rem;
                border-width: 2rem;
            }
        }

        &Decor._8 {
            right: -2rem;
            bottom: 20rem;
            width: 26rem;
            height: 26rem;
            border: 4rem solid #5d279e;
            border-radius: 50%;
            animation: kaInfoDecor8 1.2s infinite ease-in-out;

            @keyframes kaInfoDecor8 {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1);
                }
            }

            @include mob {
                right: -5rem;
                bottom: 40rem;
                width: 18rem;
                height: 18rem;
                border-width: 3rem;
            }
        }
    }

    &__prizes {
        position: relative;
        z-index: 1;

        & + & {
            margin-top: 100rem;

            @include mob {
                margin-top: 70rem;
            }
        }

        &Title {
            margin-bottom: 30rem;
            color: #fff;
            font-weight: 600;
            font-size: 32rem;
            line-height: 1.3;
            text-align: center;

            @include mob {
                font-size: 26rem;
            }
        }

        &Content {
            position: relative;
            z-index: 1;
            padding: 60rem 60rem 48rem;
            overflow: hidden;
            background: #ff1c24;
            border-radius: 64rem;

            @include mob {
                z-index: 3;
                padding: 50rem 40rem 30rem;
                border-radius: 48rem;
            }
        }

        &Slider {
            position: relative;
            padding: 0 120rem;

            @include mob {
                padding: 0;
            }

            &Button {
                position: absolute;
                top: 50%;
                z-index: 3;
                width: 60rem;
                height: 60rem;
                margin-top: -34rem;
                background: #fff;
                border-radius: 50%;
                transform: translate(0, -50%);

                @include mob {
                    width: 44rem;
                    height: 44rem;
                    margin-top: -20rem;
                }

                &._prev {
                    left: -20rem;
                }

                &._next {
                    right: -20rem;
                }

                &Icon {
                    width: 24rem;
                    height: 24rem;

                    @include icon(#e11f26);

                    @include mob {
                        width: 18rem;
                        height: 18rem;
                    }
                }
            }

            &Items {
                display: inline-flex;
            }

            &Item {
                width: 220rem;
                margin-right: 60rem;
                transition: $trans;

                @include mob {
                    width: 290rem;
                    margin-right: 30rem;
                }

                @include desk {
                    &:not(._current) {
                        opacity: 0;
                    }
                }

                &Head {
                    width: 220rem;
                    height: 220rem;
                    margin-bottom: 28rem;
                    background: #ff49b8;
                    border-radius: 50%;

                    @include mob {
                        width: 200rem;
                        height: 200rem;
                    }
                }

                &Image {
                    object-fit: contain;
                }

                &Title {
                    display: flex;
                    align-items: center;
                    min-height: 48rem;
                    color: #fff;
                    font-weight: 600;
                    font-size: 18rem;
                    line-height: 1.3;
                    text-align: center;
                    text-transform: uppercase;

                    @include mob {
                        max-width: 90%;
                    }
                }
            }
        }

        &Button {
            width: 220rem;
            margin-top: 36rem;

            @include mob {
                width: auto;
            }
        }
    }

    &__prizesDecor,
    &__prizesContentDecor {
        position: absolute;
        z-index: 2;
        pointer-events: none;
    }

    &__prizesDecor._weekly._1 {
        top: 162rem;
        right: -132rem;
        width: 50rem;
        height: 29rem;
        animation: kaPrizesDecorWeekly1 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-5.svg", contain, center);

        @keyframes kaPrizesDecorWeekly1 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(-4rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: -30rem;
            right: -10rem;
        }
    }

    &__prizesDecor._weekly._2 {
        top: 178rem;
        right: -126rem;
        z-index: 2;
        width: 24rem;
        height: 17rem;
        animation: kaPrizesDecorWeekly2 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-6.svg", contain, center);

        @keyframes kaPrizesDecorWeekly2 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(4rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: -14rem;
            right: 6rem;
        }
    }

    &__prizesDecor._weekly._3 {
        right: -90rem;
        bottom: -100rem;
        z-index: 2;
        width: 150rem;
        height: 89rem;
        animation: kaPrizesDecorWeekly3 3s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-7.svg", contain, center);

        @keyframes kaPrizesDecorWeekly3 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(40rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            right: -20rem;
            bottom: -80rem;
            width: 130rem;
            height: 69rem;
        }
    }

    &__prizesContentDecor._weekly._1 {
        bottom: 65rem;
        left: 62rem;
        width: 23rem;
        height: 18rem;
        animation: kaPrizesDecorContentWeekly1 3s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-1.svg", contain, center);

        @keyframes kaPrizesDecorContentWeekly1 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(-40rem, 40rem);
                opacity: 0;
            }
        }

        @include mob {
            bottom: 32rem;
            left: 32rem;
        }
    }

    &__prizesContentDecor._weekly._2 {
        bottom: 103rem;
        left: 113rem;
        width: 16rem;
        height: 36rem;
        animation: kaPrizesDecorContentWeekly2 3s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2.svg", contain, center);

        @keyframes kaPrizesDecorContentWeekly2 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(-40rem, -25rem);
                opacity: 0;
            }
        }

        @include mob {
            top: 50rem;
            bottom: auto;
            left: 50rem;
        }
    }

    &__prizesContentDecor._weekly._3 {
        right: 35rem;
        bottom: 118rem;
        width: 22rem;
        height: 19rem;
        animation: kaPrizesDecorContentWeekly3 2.5s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-3.svg", contain, center);

        @keyframes kaPrizesDecorContentWeekly3 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(20rem, 10rem);
                opacity: 0;
            }
        }
    }

    &__prizesContentDecor._weekly._4 {
        right: 64rem;
        bottom: 69rem;
        width: 20rem;
        height: 18rem;
        animation: kaPrizesDecorContentWeekly4 2.5s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-4.svg", contain, center);

        @keyframes kaPrizesDecorContentWeekly4 {
            0% {
                transform: translate(0, 0);
                opacity: 0;
            }

            20% {
                opacity: 1;
            }

            80% {
                opacity: 1;
            }

            100% {
                transform: translate(10rem, 35rem);
                opacity: 0;
            }
        }
    }

    &__prizesContentDecor._weekly._5 {
        top: 47rem;
        right: 111rem;
        width: 14rem;
        height: 14rem;
        border: 3rem solid #ff49b8;
        border-radius: 50%;
        animation: kaPrizesDecorContentWeekly5 2.5s infinite ease-in-out;

        @keyframes kaPrizesDecorContentWeekly5 {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.5);
            }

            100% {
                transform: scale(1);
            }
        }

        @include mob {
            top: 15rem;
            width: 12rem;
            height: 12rem;
        }
    }

    &__prizesDecor._vip._1 {
        top: 20rem;
        left: -140rem;
        width: 42rem;
        height: 25rem;
        animation: kaPrizesDecorVip1 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2-5.svg", contain, center);

        @keyframes kaPrizesDecorVip1 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(-4rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: -10rem;
            left: -10rem;
        }
    }

    &__prizesDecor._vip._2 {
        top: 35rem;
        left: -124rem;
        width: 21rem;
        height: 14rem;
        animation: kaPrizesDecorVip2 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2-6.svg", contain, center);

        @keyframes kaPrizesDecorVip2 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(4rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: 6rem;
            left: 0;
        }
    }

    &__prizesDecor._vip._3 {
        top: 115rem;
        left: -254rem;
        width: 165rem;
        height: 57rem;
        animation: kaPrizesDecorVip3 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2-7.svg", contain, center);

        @keyframes kaPrizesDecorVip3 {
            0% {
                transform: scaleX(1);
            }

            50% {
                transform: scaleX(1.2);
            }

            100% {
                transform: scaleX(1);
            }
        }

        @include mob {
            top: 100rem;
            left: -50rem;
            display: none;
            width: 120rem;
            height: 47rem;
        }
    }

    &__prizesDecor._vip._4 {
        bottom: 38rem;
        left: -270rem;
        display: flex;
        width: 184rem;
        height: 184rem;
        background: repeating-linear-gradient(#e11f26, #e11f26 4.5rem, #ea232b 4.5rem, #ea232b 14.2rem);
        background-position: 0 0;
        border-radius: 50%;
        transform: rotate(-45deg);
        animation: kaPrizesDecorVip4 4s infinite linear;

        &::before {
            z-index: 2;
            display: block;
            width: 84rem;
            height: 84rem;
            margin: auto;
            background: #e11f26;
            border-radius: 50%;
            content: "";
        }

        @keyframes kaPrizesDecorVip4 {
            0% {
                background-position: 0 0;
            }

            100% {
                background-position: 184rem 184rem;
            }
        }

        @include mob {
            display: none;
        }
    }

    &__prizesDecor._vip._5 {
        right: -114rem;
        bottom: 152rem;
        width: 31rem;
        height: 18rem;

        // animation: kaPrizesDecorVip5 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2-8.svg", contain, center);

        @keyframes kaPrizesDecorVip5 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(4rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            display: none;
        }
    }

    &__prizesDecor._vip._6 {
        right: -100rem;
        bottom: 162rem;
        width: 13rem;
        height: 9rem;
        animation: kaPrizesDecorVip6 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2-9.svg", contain, center);

        @keyframes kaPrizesDecorVip6 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(-4rem, 0);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            display: none;
        }
    }

    &__prizesContentDecor._vip._1 {
        bottom: 128rem;
        left: 63rem;
        width: 16rem;
        height: 34rem;
        animation: kaPrizesContentDecorVip1 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2-1.svg", contain, center);

        @keyframes kaPrizesContentDecorVip1 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(-5rem, -10rem);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            top: 26rem;
            bottom: auto;
            left: 50rem;
            width: 14rem;
            height: 30rem;
        }
    }

    &__prizesContentDecor._vip._2 {
        bottom: 49rem;
        left: 210rem;
        width: 45rem;
        height: 58rem;
        animation: kaPrizesContentDecorVip2 0.7s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2-2.svg", contain, center);

        @keyframes kaPrizesContentDecorVip2 {
            0% {
                transform: translate(0, 0);
            }

            50% {
                transform: translate(0, -4rem);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include mob {
            left: 30rem;
        }
    }

    &__prizesContentDecor._vip._3 {
        right: 223rem;
        bottom: -230rem;
        z-index: -1;
        width: 490rem;
        height: 520rem;
        animation: kaPrizesContentDecorVip3 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2-3.svg", contain, center);

        @keyframes kaPrizesContentDecorVip3 {
            0% {
                transform: rotate(0deg);
            }

            50% {
                transform: rotate(-10deg) scale(1.2);
            }

            100% {
                transform: rotate(0deg);
            }
        }

        @include mob {
            right: -160rem;
            width: 450rem;
            height: 490rem;
        }
    }

    &__prizesContentDecor._vip._4 {
        right: 91rem;
        bottom: 70rem;
        width: 48rem;
        height: 40rem;
        animation: kaPrizesContentDecorVip4 2s infinite ease-in-out;

        @include bg("/media/5ka/prizesDecor-2-4.svg", contain, center);

        @keyframes kaPrizesContentDecorVip4 {
            0% {
                transform: rotate(0deg);
            }

            50% {
                transform: translate(0, 15rem) rotate(10deg);
            }

            100% {
                transform: rotate(0deg);
            }
        }

        @include mob {
            display: none;
        }
    }

    &__prizesContentDecor._vip._5 {
        right: 54rem;
        bottom: 160rem;
        width: 14rem;
        height: 14rem;
        border: 3rem solid #ffd100;
        border-radius: 50%;
        animation: kaPrizesContentDecorVip5 1s infinite ease-in-out;

        @keyframes kaPrizesContentDecorVip5 {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.3);
            }

            100% {
                transform: scale(1);
            }
        }

        @include mob {
            right: 40rem;
            bottom: 70rem;
        }
    }

    &__prizesContentDecor._vip._6 {
        right: 80rem;
        bottom: 192rem;
        width: 24rem;
        height: 24rem;
        border: 5rem solid #ff49b8;
        border-radius: 50%;
        animation: kaPrizesContentDecorVip6 1s infinite ease-in-out;

        @keyframes kaPrizesContentDecorVip6 {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(0.7);
            }

            100% {
                transform: scale(1);
            }
        }

        @include mob {
            right: 64rem;
            bottom: 100rem;
        }
    }
}
