.menu {
    padding: 20rem 0;
    overflow: hidden;
    overflow-y: auto;

    @include mob {
        padding: 30rem 0;
    }

    &__head {
        height: 250rem;
        margin-bottom: 78rem;

        @include mob {
            flex-direction: column-reverse;
            height: auto;
            margin-bottom: 90rem;
        }

        &Nav {
            width: calc(100% - 418rem);
            height: 100%;
            padding-right: 32rem;

            @include mob {
                width: 100%;
                height: auto;
                padding-right: 0;
            }
        }

        &Actions {
            width: 418rem;
            height: 100%;

            @include mob {
                width: 100%;
                height: auto;
                margin-bottom: 24rem;
            }
        }
    }

    &__nav {
        display: flex;
        flex-flow: column wrap;
        height: 100%;

        @include mob {
            flex-flow: column nowrap;
            height: auto;
        }

        &Item {
            margin-right: 10rem;
            margin-bottom: 9rem;
            padding: 14rem 0;
            border-bottom: 2rem solid #f72d25;

            @include mob {
                width: 100%;
                margin-right: 0;
                margin-bottom: 12rem;
                padding: 18rem 0;
            }

            @include desk {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    width: 43%;
                }

                &:nth-child(4),
                &:nth-child(8) {
                    margin-bottom: 0;
                }
            }

            &Link {
                color: #fff;
                font-weight: 600;
                font-size: 24rem;
                white-space: nowrap;
                transition: $trans;

                @include mob {
                    font-size: 25rem;
                }

                &:hover {
                    color: #ffd100;
                }
            }
        }
    }

    &__actions {
        margin-top: -6rem;
    }

    &__action {
        position: relative;
        z-index: 1;

        & + & {
            margin-top: 10rem;

            @include mob {
                margin-top: 32rem;
            }
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 48rem;
            z-index: -1;
            width: 50vw;
            height: 56rem;
            transform: translate(0, -50%);
            content: "";

            @include mob {
                left: -32rem;
                width: 364rem;
                height: 52rem;
            }
        }

        &._code::after,
        &._code &Back {
            background: #5d279e;
        }

        &._pyt::after,
        &._pyt &Back {
            background: #ff3427;
        }

        &._lk::after,
        &._lk &Back {
            background: #ff49b8;
        }

        &::before {
            position: absolute;
            z-index: 3;
            content: "";
        }

        &._code::before {
            top: 3rem;
            left: 56rem;
            width: 50rem;
            height: 50rem;

            @include mob {
                top: -7rem;
                right: 64rem;
                left: auto;
                width: 46rem;
                height: 46rem;
            }

            @include bg("/media/menu-start.svg", contain, center);
        }

        &._pyt::before {
            top: 4rem;
            left: 55rem;
            width: 52rem;
            height: 52rem;

            @include mob {
                top: -8rem;
                right: 63rem;
                left: auto;
                width: 48rem;
                height: 48rem;
            }

            @include bg("/media/logo-5ka.svg", contain, center);
        }

        &._lk::before {
            top: 5rem;
            left: 55rem;
            width: 50rem;
            height: 50rem;

            @include mob {
                top: -6rem;
                right: 63rem;
                left: auto;
                width: 48rem;
                height: 48rem;
            }

            @include bg("/media/menu-smile.svg", contain, center);
        }

        &Back {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;
            display: flex;
            width: 49rem;
            height: 76rem;
            border-radius: 14rem;
            transform: translate(0, -50%);

            @include mob {
                right: 0;
                left: auto;
                width: 45rem;
                height: 70rem;
                border-radius: 12rem;
            }

            &::before {
                display: block;
                box-sizing: border-box;
                width: 58%;
                height: 70%;
                margin: auto;
                background: #e11f26;
                border-radius: 10rem;
                content: "";

                @include mob {
                    border-radius: 9rem;
                }
            }
        }

        &Inner {
            position: relative;
            z-index: 1;
            height: 56rem;
            overflow: hidden;

            @include mob {
                height: 52rem;
            }
        }

        &Content {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-left: 126rem;
            color: #fff;
            font-weight: 600;
            font-size: 24rem;

            @include mob {
                padding-left: 0;
                font-size: 24rem;
            }

            &._hide {
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(0, 100%);
            }
        }

        @keyframes menuContent {
            0% {
                transform: translate(0, 0);
            }

            100% {
                transform: translate(0, -56rem);
            }
        }

        @keyframes menuHideContent {
            0% {
                transform: translate(0, 100%);
            }

            100% {
                transform: translate(0, 0);
            }
        }

        @include desk {
            &:hover &Content:not(._hide) {
                animation: 0.3s 1 menuContent ease-in-out;
            }

            &:hover &Content._hide {
                animation: 0.3s 1 menuHideContent ease-in-out;
            }
        }
    }

    &__prizes {
        position: relative;
        padding: 52rem 28rem 48rem;
        background: rgba(#f72d25, 0.5);
        border-radius: 32rem;

        @include mob {
            padding: 92rem 8rem 20rem;
        }

        &Title {
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 2;
            max-width: calc(100% - 32rem);
            padding: 13rem 24rem 12rem;
            color: $colorMain;
            font-weight: 600;
            font-size: 20rem;
            line-height: 1.3;
            text-align: center;
            background: #fff;
            border-radius: 12rem;
            transform: translate(-50%, -50%);

            @include desk {
                white-space: nowrap;
            }

            @include mob {
                width: calc(100% - 40rem);
                max-width: none;
                line-height: 1.3;
                border-radius: 15rem;
            }
        }

        &Items {
            flex-wrap: wrap;

            @include mob {
                margin-bottom: -32rem;
            }
        }

        &Item {
            width: 25%;

            @include mob {
                width: 50%;
                margin-bottom: 32rem;
            }
        }
    }

    &__prize {
        &Head {
            width: 204rem;
            height: 204rem;
            margin-bottom: 14rem;

            @include mob {
                width: 140rem;
                height: 140rem;
                margin-bottom: 18rem;
            }
        }

        &Image {
            width: 130%;
            height: 130%;
            object-fit: contain;
            transition: $trans;
            will-change: transform;
        }

        &:hover &Image {
            transform: scale(1.05);
        }

        &Content {
            color: #fff;
            font-weight: 600;
            font-size: 20rem;
            text-align: center;
        }
    }
}
